import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import Logo from '../../assets/logo.svg';
import constants from '../../constants';

const LogoId = ({ readableId, navigatorLanguage, generatedAt }) => (
  <Row className='middle-xs header__nav__id'>
    <Col xs={5} md={5} className='header__nav__id__logo'>
        <img src={Logo} alt='logo-monspécialisteauto' />
    </Col>
    {readableId && (
      <Col xs={8} md={8} lg={6}>
        <div className='header__nav__title'>
          <span className='greyLabel weight200'>{_.get(constants, `lang.${navigatorLanguage}.report`)}</span>
          <span className='value blackValue'>ID {readableId}</span>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span>
            {_.get(constants, `lang.${navigatorLanguage}.labelOfTheReportGenerationDate`)} {moment(generatedAt).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      </Col>
    )}
  </Row>
);

LogoId.propTypes = {
  readableId: PropTypes.number,
  navigatorLanguage: PropTypes.string,
};

export default LogoId;
