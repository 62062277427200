import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Text from '../Text';
import LightBox from './LightBox';
import RenderContent from './RenderContent';

const Thumbnail = ({ checkpoint, checkpoints, elementKey, openImage, navigatorLanguage, hasLabel = false, noHide, enableHover, classNames, pdfView }) => {
  const imgRef = useRef(null);
  const [currentCheckpoint, setCurrentCheckpoint] = useState({});
  const updateCurrentCheckpoint = (newCheckpoint) => setCurrentCheckpoint(() => newCheckpoint);

  useEffect(() => {
    updateCurrentCheckpoint(checkpoint);
  }, [checkpoint]);

  const prevSrc = () => {
    const indexPrev = checkpoints.findIndex((checkpoint) => checkpoint._id === currentCheckpoint._id);
    return updateCurrentCheckpoint(checkpoints[(indexPrev + checkpoints.length - 1) % checkpoints.length]);
  };

  const nextSrc = () => {
    const indexPrev = checkpoints.findIndex((checkpoint) => checkpoint._id === currentCheckpoint._id);
    return updateCurrentCheckpoint(checkpoints[(indexPrev + 1) % checkpoints.length]);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = (action) => {
    const openModal = action === 'open';
    setIsOpen(openModal);
  };

  const hover = !isOpen ? enableHover : true;
  const setClassNames = !isOpen ? classNames : '';

  const transformPoint = (points) => {
    if (!points) return false;

    const newWidth = Math.floor((points.height * 16) / 9);
    const diff = Math.floor((newWidth - points.width) / 2);

    return {
      x: (points.x * 4) / 3 - diff,
      y: points.y,
      dimensions: points.dimensions,
    };
  };

  const getCoordsFromAnomaly = useCallback(
    (anomaly, needResize, element, img) => {
      const container = img.current;
      const height = container && container.clientHeight;
      const width = container && container.clientWidth;
      const fourThirdsDoubleCheck = width / height < 16 / 9;

      if (!height || !width) return false;
      if (needResize && fourThirdsDoubleCheck) {
        const transforms = transformPoint({
          x: isOpen ? width * anomaly.reelx + container.offsetLeft : width * anomaly.reelx,
          y: isOpen ? height * anomaly.reely + container.offsetTop : height * anomaly.reely,
          width,
          height,
          dimensions: element.dimensions,
        });
        return {
          dimensions: {
            width,
            height,
          },
          x: transforms.x,
          y: transforms.y,
        };
      }
      return {
        x: isOpen ? width * anomaly.reelx + container.offsetLeft : width * anomaly.reelx,
        y: isOpen ? height * anomaly.reely + container.offsetTop : height * anomaly.reely,
        dimensions: {
          width,
          height,
        },
      };
    },
    [isOpen],
  );

  const getOrientationAnomaly = (element, anomaly, img) => {
    const half = Math.round(((img && img.clientWidth) || 500) / 2);
    if (!anomaly || !element || !element.dimensions) return { left: 0, top: 0, position: 'absolute ' };
    const x = img.current.clientWidth * anomaly.reelx;
    const y = img.current.clientHeight * anomaly.reely;
    const more = x < half ? 'left' : 'right';

    switch (more) {
      case 'right':
        if (anomaly.reely > 0.97) return { left: x - 200, bottom: -y - 10, position: 'absolute' };
        return { left: x - 200, top: y - 10, position: 'absolute' };
      default:
        if (anomaly.reely > 0.97) return { left: x - 10, bottom: -y - 10, position: 'absolute' };
        return { left: x - 10, top: y - 10, position: 'absolute' };
    }
  };

  const [size, setSize] = useState([0, 0]);
  const updateSize = () => setSize(imgRef.current.clientWidth, imgRef.current.clientHeight);
  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const [anomalies, setAnomalies] = useState([]);

  const setNewAnomalies = useCallback(() => {
    const arrayAnomalies =
      currentCheckpoint &&
      currentCheckpoint.anomalies &&
      currentCheckpoint.anomalies.map((anomaly) => {
        if (!anomaly) return null;

        const newCoords = getCoordsFromAnomaly(anomaly, currentCheckpoint['4_3'], currentCheckpoint, imgRef);
        if (newCoords) {
          /* eslint-disable no-param-reassign */
          anomaly.x = newCoords.x;
          anomaly.y = newCoords.y;
          currentCheckpoint.old_dimensions = currentCheckpoint.old_dimensions ? currentCheckpoint.old_dimensions : currentCheckpoint.dimensions;
          currentCheckpoint.dimensions = newCoords.dimensions;
        }
        if (!currentCheckpoint.dimensions) {
          currentCheckpoint.dimensions = { width: 500, height: 500 };
        }
        anomaly.orientation = getOrientationAnomaly(currentCheckpoint, anomaly, imgRef);
        return anomaly;
      });
    return setAnomalies(arrayAnomalies);
  }, [currentCheckpoint, getCoordsFromAnomaly]);

  useEffect(() => {
    setNewAnomalies();
  }, [isOpen, setNewAnomalies, size]);

  useEffect(() => {
    updateCurrentCheckpoint(checkpoint);
  }, [checkpoint, isOpen]);

  const imgProps = {
    anomalies,
    imgRef,
    element: currentCheckpoint,
    elementKey,
    openAnomaly: openImage,
    isOpen,
    noHide,
    enableHover: hover,
    classNames: setClassNames,
    updateDimensions: setNewAnomalies,
  };
  return (
    <div>
      {isOpen && <LightBox imgProps={imgProps} handleOpen={handleOpen} prevSrc={prevSrc} nextSrc={nextSrc} />}
      {!isOpen && <RenderContent pdfView={pdfView} imgProps={imgProps} handleOpen={handleOpen} />}
      {!isOpen && hasLabel && (
        <Text className='darkLabel weight500 center p10px'>
          {navigatorLanguage && currentCheckpoint.labelTranslation ? currentCheckpoint.labelTranslation : currentCheckpoint.label}
        </Text>
      )}
    </div>
  );
};

Thumbnail.propTypes = {
  checkpoint: PropTypes.object,
  checkpoints: PropTypes.array,
  elementKey: PropTypes.number,
  openImage: PropTypes.func,
  navigatorLanguage: PropTypes.string,
  hasLabel: PropTypes.bool,
  noHide: PropTypes.bool,
  enableHover: PropTypes.bool,
  classNames: PropTypes.string,
  pdfView: PropTypes.string,
};

export default Thumbnail;
