import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../constants';

const Elements = ({ checkpoint, navigatorLanguage, classForPiece = '' }) => {
  if (!checkpoint.kind) {
    return (
      <>
        <div className={classForPiece}>
          <span>
            <b>{_.get(constants, `lang.${navigatorLanguage}.element`)} : </b>
          </span>
          <span>
            <b>
              {checkpoint.element} / {checkpoint.damageType}
            </b>
          </span>
        </div>
        <div className={`damageTypeAndSeverity ${classForPiece}`}>
          <div>
            <span>{_.get(constants, `lang.${navigatorLanguage}.reparationType`)} : </span>
            <span>
              <b>{checkpoint.reparationType}</b>
            </span>
          </div>
          {checkpoint.severityCm >= 0 && (
            <div>
              |<span> {_.get(constants, `lang.${navigatorLanguage}.severity`)} : </span>
              <span> {checkpoint.severityCm} cm </span>
            </div>
          )}
        </div>
        {checkpoint.repairOperationId && (
          <div className={classForPiece}>
            <span>{_.get(constants, `lang.${navigatorLanguage}.repairOperationId`)} : </span>
            <span> {checkpoint.repairOperationId} </span>
          </div>
        )}
      </>
    );
  }

  switch (checkpoint.kind) {
    case 'CommentCheckpoint':
      return (
        <>
          <div>
            <span>
              <b>{checkpoint.labelTranslation || checkpoint.label}</b> :
            </span>
            <span>
              <b> {checkpoint.valueLabel}</b>
            </span>
          </div>
        </>
      );

    default:
      return (
        <>
          <div>
            <span>{_.get(constants, `lang.${navigatorLanguage}.checkpoint`)} : </span>
            <span>
              <b>{checkpoint.labelTranslation || checkpoint.label}</b>
            </span>
          </div>
          <div>
            <span>{_.get(constants, `lang.${navigatorLanguage}.response`)} : </span>
            <span>
              <b>{checkpoint.valueLabel}</b>
            </span>
          </div>
        </>
      );
  }
};

Elements.propTypes = {
  checkpoint: PropTypes.object,
};

export default Elements;
