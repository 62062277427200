export const getRightReportPdfV1 = (documentList, shownPrices, lang) => {
  let result;
  const documentWithLangAndUrl = documentList.find((doc) => doc?.lang === lang && doc?.url !== undefined && shownPrices);
  if (documentWithLangAndUrl) {
    result = documentWithLangAndUrl.url;
  } else {
    const documentWithLangAndType = documentList.find((doc) => doc?.lang === lang && doc?.type === 'PDF_Report_No_Cost' && doc?.url !== undefined);
    if (documentWithLangAndType) {
      result = documentWithLangAndType.url;
    }
  }
  return result;
};
