import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import Thumbnail from '../Thumbnail';

const Photo = ({ section = {}, openImage, navigatorLanguage }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { checkpoints } = section;
  const checkpointsFiltered = checkpoints.filter((checkpoint) => checkpoint.uri !== undefined);
  return (
    <div>
      <Row className='mb3 viewer-photos'>
        {checkpointsFiltered &&
          checkpointsFiltered.map((checkpoint, key) => (
            <Col xs={12} md={4} key={key} className='block__image viewer-photos__big pt6 px3 photoSectionPadding'>
              <Thumbnail
                checkpoints={checkpointsFiltered}
                checkpoint={checkpoint}
                elementKey={key}
                openImage={openImage}
                navigatorLanguage={navigatorLanguage}
                hasLabel={true}
                noHide
                enableHover
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

Photo.propTypes = {
  section: PropTypes.object,
  openImage: PropTypes.func,
  navigatorLanguage: PropTypes.string,
};

export default Photo;
