const findSectionWithKey = (sections, key) => sections
  .find((section) => {
    const arr = [];
    // eslint-disable-next-line no-unused-expressions
    section.checkpoints && section.checkpoints.forEach((ckpoint) => {
      if (ckpoint.key && ckpoint.key === key) {
        arr.push(ckpoint);
      }
    });
    return arr.length > 0 && section;
  });

export default findSectionWithKey;
