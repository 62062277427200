import PropTypes from 'prop-types';
import React from 'react';

const ViewAnomaly = ({ onHover, children, noHide = false, enableHover }) => (
  <div className='hover'>
    <div className={`${noHide && !enableHover ? 'hover__no-hide' : 'hover__no-hover'}`}>{children}</div>
    {noHide && <div className='hover__hover'>{onHover}</div>}
  </div>
);

ViewAnomaly.propTypes = {
  onHover: PropTypes.element,
  children: PropTypes.element,
  noHide: PropTypes.bool,
  enableHover: PropTypes.bool,
};

export default ViewAnomaly;
