import { Form, Input, InputNumber, Popconfirm, Table, TimePicker, Select, Button } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import constants from '../../../../constants';
import convertValueEnterToHours from '../../../../helpers/convertValueEnterToHours';
import moment from 'moment/moment';
import { formatTimes } from '../../../../helpers/formatTimes';
import { getCorrespondenceForLabourHours } from '../../../../helpers/getCorrespondenceForLabourHours';
import { v4 as uuidv4 } from 'uuid';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  inputType,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  let childNode = children;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const inputMap = {
    text: <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
    number: <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />,
    timer: <TimePicker minuteStep={15} format={constants.formatTime} ref={inputRef} onPressEnter={save} onBlur={save} />,
    select: <Select
      ref={inputRef}
      showSearch
      placeholder="Select a person"
      optionFilterProp="children"
      onPressEnter={save}
      onBlur={save}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={constants.typeAndLabelRepairCostTMO}
    />
  }

  const getInputToRender = (inputType) => {
    return inputMap[inputType];
  }

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {getInputToRender(inputType)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const PieceTable = ({
  adminCanEdit,
  checkpoint,
  checked,
  updateDateToSend,
}) => {
  const pb = checked ? 'publicCost' : 'privateCost';
  const depre = checked ? 'publicCostDepreciation' : 'privateCostDepreciation';
  const [count, setCount] = useState(0);
  const [dataToSend, setDataToSend] = useState([]);
  const [dataSource, setDataSource] = useState();

  const defaultColumns = [
    {
      title: 'Type',
      dataIndex: 'name',
      width: '6%',
      editable: adminCanEdit,
      render: (name) => {
        return (
          <span className='textTable'>
            {name ? constants.typeAndLabelRepairCostTMO.filter((type) => type.value === name && type.label)[0].label  : '-' }
          </span>
        )
      },
    },
    {
      title: 'Pièce',
      dataIndex: 'pieceName',
      editable: adminCanEdit,
      render: (name) => {
        return (
          <span className='textTable'>
            {name || '-' }
          </span>
        )
      },
    },
    {
      title: 'Taux horaire',
      dataIndex: 'th',
      editable: adminCanEdit,
      render: (th) => <span className='textTable'>{th && parseInt(th) > 0 ? th + '€' :  0 + '€'} </span>,
    },
    {
      title: 'Temps opération',
      dataIndex: 'to',
      editable: adminCanEdit,
      render: (hours) => {
        return (
          <span className='textTable lowercase'>
            {(hours && typeof hours === 'object') ? formatTimes(new Date(hours)) : '-'}
          </span>
        )
      },
    },
    {
      title: 'Dépré ciation', // The space between the word reduces the visual display
      dataIndex: depre,
      editable: adminCanEdit,
      width: '7%',
      render: (depreciation) => <span className='textTable'>{depreciation && parseInt(depreciation) > 0 ? depreciation + '%' : '-'} </span>,
    },
    {
      title: 'Coût pièce',
      dataIndex: 'pieceCost',
      editable: adminCanEdit,
      render: (pieceCost) => <span className='textTable'>{pieceCost && parseInt(pieceCost) > 0 ? pieceCost + '€' :  0 + '€'} </span>,
    },
    {
      title: 'Prix Net',
      dataIndex: 'pn',
      editable: false,
      render: (pn) => {
        return (
          <span className='textTable lowercase'>
            {pn > 0 ? parseFloat(pn).toFixed(2) + '€' : 0 + '€'}
          </span>
        )
      },
    },
    ...(adminCanEdit
      ? [{
        title: '',
        dataIndex: 'operation',
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="Confirmez-vous cette suppression?" onConfirm={() => handleDelete(record.key)}>
              <span><i className="fas fa-trash-alt"></i></span>
            </Popconfirm>
          ) : null,
        className: '--hide-from-pdf',
      }]
      : []),
  ];

  const handleSave = (row) => {
    let value = {};
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    let operationTime = item.labourCost || 0;
    const oldRow = {...row};
    if (moment(row.to, constants.formatDateTime, true).isValid() && !moment(row.to).isSame(item.to)) {
      operationTime = convertValueEnterToHours(row.to, true);
    }

    const pb = oldRow.th * operationTime;

    if (oldRow[depre] > 0) {
      const cp = (oldRow.pieceCost - ((oldRow.pieceCost * oldRow[depre]) / 100));
      value = { ...oldRow, pieceCost: cp, pn: (pb + cp)}
    } else {
      value = { ...oldRow, pn: (pb + oldRow.pieceCost) };
    }

    newData.splice(index, 1, {
      ...item,
      ...value,
      labourCost: operationTime,
      publicCost: (value.pn).toFixed(2),
      typeLabourCost: row.name.split("_")[0],
      levelLabourCost: row.name.split("_")[1]
    });
    setDataToSend(newData)
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData = {
      key: dataSource.length + 1,
      name: 'body_T1',
      pieceName: 'Pneu',
      th: 0,
      to: 0,
      [depre]: 0,
      pieceCost: 0,
      pn: 0,
      [pb]: 0,
      id: uuidv4(),
      defectOrCheckpointId: checkpoint.defectId || checkpoint._id,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => (item.key !== key));
    const newDataToSend = dataSource.map(v => (v.key === key) && ({...v, isDelete: true}))
    setDataToSend(newDataToSend)
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['th', 'publicCost', 'privateCost', 'publicCostDepreciation', 'privateCostDepreciation', 'pieceCost'].includes(col.dataIndex) ? 'number' : (col.dataIndex === 'to') ? 'timer' : (col.dataIndex === 'name') ? 'select' : 'text',
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    updateDateToSend(dataToSend);
  }, [dataToSend]);

  useEffect(() => {
    const data = [];
    const { labourTimeInfos, privateCostDepreciation, publicCostDepreciation } = checkpoint;
    labourTimeInfos && labourTimeInfos.forEach((labourTimeInfo, indexLabourTimeInfo) => {
      let priceHour = (labourTimeInfo.computedLabourCost - (labourTimeInfo.pieceCost || 0)) / labourTimeInfo.hours;
      const today = new Date();
      const splitHours = String(labourTimeInfo.hours).split(".");
      const formattedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(splitHours[0], 10) || 0, parseInt(getCorrespondenceForLabourHours(splitHours[1]), 10) || 0, 0, 0);
      let depreciation = 0;

      if (privateCostDepreciation || publicCostDepreciation) {
        if (!checked) {
          depreciation = privateCostDepreciation || 0;
        } else if (checked) {
          depreciation = publicCostDepreciation || 0;
        }
      }
      data.push({
        key: indexLabourTimeInfo,
        name: `${labourTimeInfo.type}_${labourTimeInfo.level}`,
        pieceName: labourTimeInfo.pieceName,
        th: `${(priceHour && priceHour.toFixed(2)) || 0}`,
        to: moment(formattedDate),
        [pb]: `${(labourTimeInfo.computedLabourCost && labourTimeInfo.computedLabourCost.toFixed(2)) || 0}`,
        [depre]: `${depreciation || 0}`,
        pieceCost: labourTimeInfo.pieceCost,
        pn: labourTimeInfo.computedLabourCost,
        typeLabourCost: labourTimeInfo.type,
        levelLabourCost: labourTimeInfo.level,
        id: labourTimeInfo.id,
        defectOrCheckpointId: checkpoint.defectId || checkpoint._id,
        labourCost: labourTimeInfo.hours
      });
    });
    setCount(data.length);
    setDataSource(data);
  }, []);

  return (
    <div>
      {adminCanEdit && (
        <Button
          className='--hide-from-pdf'
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 2,
          }}
        >
          Ajouter une ligne
        </Button>
      )}
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};
export default PieceTable;
