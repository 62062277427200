import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-grid-system';

const Section = ({ section, checkpoints, type, showCheckpoint, navigatorLanguage, canEdit, canSeeFullReport, pdfView }) => {
  const checkpointsFilter = (checkpoints) => checkpoints && checkpoints.filter((checkpoint) => !checkpoint.hideInViewer);
  const userDisplayedCheckpoints = !canSeeFullReport ? checkpointsFilter(checkpoints) : checkpoints;
  const ckPointsLeft =
    userDisplayedCheckpoints && userDisplayedCheckpoints.filter((checkpoint) => checkpoint.kind !== 'PhotoCheckpoint').slice(0, Math.ceil(checkpoints.length / 2));
  const ckPointsRight =
    userDisplayedCheckpoints && userDisplayedCheckpoints.filter((checkpoint) => checkpoint.kind !== 'PhotoCheckpoint').slice(Math.ceil(checkpoints.length / 2), checkpoints.length);
  const photoCheckpoint = userDisplayedCheckpoints && userDisplayedCheckpoints.filter((checkpoint) => checkpoint.kind === 'PhotoCheckpoint');

  /* eslint-disable no-unneeded-ternary */
  return (
    <div className='block__content section container'>
      <Row>
        <Col xs={12} md={12} lg={12} className='section__left px0'>
          {ckPointsLeft &&
            ckPointsLeft.map((checkpoint, checkpointKey) =>
              showCheckpoint({
                checkpoint,
                checkpointKey,
                checkpoints,
                section,
                type,
                navigatorLanguage,
                canEdit,
              }),
            )}
        </Col>
        <Col xs={12} md={12} lg={12} className='section__right px0'>
          {ckPointsRight &&
            ckPointsRight.map((checkpoint, checkpointKey) =>
              showCheckpoint({
                checkpoint,
                checkpointKey,
                checkpoints,
                section,
                type,
                navigatorLanguage,
                canEdit,
              }),
            )}
        </Col>
      </Row>
      <Row>
        {photoCheckpoint &&
          photoCheckpoint.map((checkpoint, checkpointKey) =>
            checkpoint.uri ? (
              <Col xs={12} md={12} lg={4} key={checkpointKey} style={{ marginBottom: '1rem' }}>
                {showCheckpoint({
                  checkpoint,
                  checkpointKey,
                  checkpoints,
                  section,
                  type,
                  navigatorLanguage,
                  canEdit,
                  pdfView,
                })}
              </Col>
            ) : null,
          )}
      </Row>
    </div>
  );
};

Section.propTypes = {
  section: PropTypes.object,
  checkpoints: PropTypes.array,
  showCheckpoint: PropTypes.func,
  type: PropTypes.string,
  navigatorLanguage: PropTypes.string,
  canEdit: PropTypes.bool,
  canSeeFullReport: PropTypes.bool,
  pdfView: PropTypes.string,
};

export default Section;
