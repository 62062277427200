import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';

const Router = () => (
  <BrowserRouter>
    <Switch>
      {/* <Route path="/test" render={props => React.createElement(Test, { ...props })} /> */}
      <Route path='/:inspectionId' render={(props) => React.createElement(Homepage, { ...props })} />
      <Route render={(props) => React.createElement(NotFound, { ...props })} />
    </Switch>
  </BrowserRouter>
);

Router.propTypes = {
  application: PropTypes.object,
};

const mapStateToProps = (state) => ({ application: state.application });

export default connect(mapStateToProps)(Router);
