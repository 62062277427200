import PropTypes from 'prop-types';
import React from 'react';
import ImageViewer from '../ImageViewer';

const RenderContent = ({ imgProps, handleOpen, pdfView }) => (
  <div className={`content picture car`}>
    <div className='pictureContainer'>
      <ImageViewer pdfView={pdfView} {...imgProps} handleOpen={handleOpen} iconZoom />
    </div>
  </div>
);

RenderContent.propTypes = {
  imgProps: PropTypes.object,
  handleOpen: PropTypes.func,
  pdfView: PropTypes.string,
};

export default RenderContent;
