import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../constants';
import convertFloatToTime from '../helpers/convertFloatToTime';
import { translateTechnicalType } from '../helpers/transformDataToDamage';
import Text from './Text';
import ViewAnomaly from './ViewAnomaly';

const RenderModalGtMotive = ({ checkpoint: repairCost, checked, checkpointKey, navigatorLanguage }) => (
  <>
    <ViewAnomaly
      noHide
      onHover={
        <div key={checkpointKey} className='anomalyContainer fre fre-infos'>
          <div className='fre-infos__title title mb3'>
            <p className='blueTitle' style={{ fontSize: 16 }}>
              <span>{_.get(constants, `lang.${navigatorLanguage}.estimate`)}</span>
              <span className='fre-infos__title__price' style={{ position: 'absolute', right: '1rem' }}>
                {repairCost.gtRepairCostInfo.total} €
              </span>
            </p>
          </div>
          {repairCost.gtRepairCostInfo.infos && (
            <div className='fre-infos__desc'>
              {repairCost.gtRepairCostInfo.infos.map((cupiVal, indexCupiVal) => (
                <div key={indexCupiVal}>
                  {indexCupiVal > 0 && <hr style={{ color: 'white' }} />}
                  <p className='fre-infos__desc__title'>{cupiVal.partInfos[0].descr}</p>
                  <div className='fre-infos__desc__content desc-content cupiInfoContainer mt0 pl6'>
                    <p className='desc-content__title'>
                      <i className='fas fa-wrench' /> {_.get(constants, `lang.${navigatorLanguage}.pieces`)}
                    </p>
                    <ul className='desc-content__liste mt0 mb0 pb6'>
                      {cupiVal.partInfos.map((value, index) => (
                        <li key={index}>
                          {value.descr} - ref. {value.constructorPartRef} <b>{value.total} €</b>
                        </li>
                      ))}
                    </ul>
                    {cupiVal.laborOperations ? (
                      <div>
                        <p className='desc-content__title'>
                          <i className='fas fa-clock' /> {_.get(constants, `lang.${navigatorLanguage}.labor`)}
                        </p>
                        <ul className='desc-content__liste mt0 pl6'>
                          {cupiVal.laborOperations.map((value, index) => {
                            if (value.masterOperation === true) {
                              return (
                                <li key={index} style={{ marginBottom: 10 }}>
                                  {value.descr} <b>{value.total} €</b> <br /> &nbsp; {translateTechnicalType(value.technicityType, navigatorLanguage)} {value.technicityLevel} -{' '}
                                  {convertFloatToTime(value.timeLabour).label}
                                  <ul style={{ marginLeft: -10, fontStyle: 'italic', color: '#464646' }}>
                                    {cupiVal.laborOperations.map((smvalue, smindex) => {
                                      if (smvalue.masterOperation !== true && smvalue.operationCode === value.operationCode) {
                                        return <li key={smindex}>{smvalue.descr}</li>;
                                      }
                                      return null;
                                    })}
                                  </ul>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      }>
      <Text className='priceValue' style={{ cursor: 'pointer' }}>
        <div className='valuePrice'>
          <span>
            <i className='fas fa-info-circle' style={{ color: '#1f5288', position: 'absolute', top: '-18px', right: 0 }} />
          </span>
        </div>
      </Text>
    </ViewAnomaly>
  </>
);

RenderModalGtMotive.propTypes = {
  checkpoint: PropTypes.object,
  checked: PropTypes.bool,
  checkpointKey: PropTypes.number,
  navigatorLanguage: PropTypes.string,
};

export default RenderModalGtMotive;
