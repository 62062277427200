import PropTypes from 'prop-types';
import React from 'react';

const ViewAnomalyPdf = ({ photosCheckpoint }) => (
  <div className='viewAnomalyPdf'>
    {photosCheckpoint.map((photo, photoKey) => (
      <a className='viewAnomalyPdf-picture' href={photo} key={photoKey} target="_blank" rel="noreferrer">
        <img className='media-img card-img-top card-img-hero' src={`https://92g2xsphz7.execute-api.eu-west-3.amazonaws.com/prod/images/resize?url=${photo}&amp;width=300`} alt={photo} style={{ width: '100%' }} />
      </a>
    ))}
  </div>
);

ViewAnomalyPdf.propTypes = {
  photosCheckpoint: PropTypes.array,
};

export default ViewAnomalyPdf;
