import _ from 'lodash';

const getColorFromValue = (checkpoint) => {
  const element = checkpoint.refCheckpoint || checkpoint;
  if (_.get(element, 'kind') === 'BoolCheckpoint' && _.has(element, 'detailsHighlight')) {
    return element.detailsHighlight === element.value ? ' red' : ' ';
  }
  if (_.get(element, 'kind') === 'CommentCheckpoint' && element.value) {
    return ' red';
  }
  if (_.get(element, 'kind') === 'EnumCheckpoint') {
    const list = _.map(element.detailsList);
    let isRed = ' ';
    _.each(list, (item) => {
      if (item.highlight === true && item.key === element.value) isRed = ' red';
    });
    return isRed;
  }
  return ' ';
};

export default getColorFromValue;
