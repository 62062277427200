import _, { sum } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import constants from '../../constants';
import convertFloatToTime from '../../helpers/convertFloatToTime';
import getDepreciationInGeneralTotalTable from '../../helpers/getDepreciationInGeneralTotalTable';
import { translateTechnicalType } from '../../helpers/transformDataToDamage';
import Card from '../Card';
import Text from '../Text';
import Total from '../Total';
import Damages from './Damages';
import Identification from './Identification';
import Option from './Option';
import PhotoSynthesis from './PhotoSynthesis';

const Synthesis = ({
  sectionIdentification,
  sectionOpt,
  sectionPhoto,
  sectionTitle,
  sectionFre,
  sectionTotal,
  checked,
  shownTotal,
  shownPrices,
  showCheckpoint,
  type,
  openLightbox,
  openImage,
  isConcession,
  onChangeFrevo,
  navigatorLanguage,
  canEdit,
  adminCanEdit,
  canSeeFullReport,
  pdfView = null,
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  let totalTtc;
  if (sectionTotal) {
    if (sectionTotal.totalPrivate && !checked) {
      totalTtc = sectionTotal.totalPrivate;
    } else if (checked && sectionTotal.totalPublic) {
      totalTtc = sectionTotal.totalPublic;
    }
  }

  const defects =
    sectionFre &&
    sectionFre.length > 0 &&
    sectionFre.reduce((array, section) => {
      return section.checkpoints && section.checkpoints.length > 0 && [...array, ...section.checkpoints];
    }, []);

  // all GT
  const arrayGt =
    defects && defects.length > 0
      ? defects
          .filter((defect) => defect.gtRepairCostInfo)
          .reduce((acc, item) => {
            const infos =
              item.gtRepairCostInfo && item.gtRepairCostInfo.infos
                ? item.gtRepairCostInfo.infos.reduce((gtInfos, info) => {
                    const partInfos = info.partInfos.map((partInfo) => ({
                      total: Number(partInfo.total),
                      descr: partInfo.descr,
                      type: 'pieces',
                    }));

                    let laborOperations = [];

                    if (info.laborOperations) {
                      laborOperations = info.laborOperations.map((laborOperation) => ({
                        type: laborOperation.technicityType,
                        total: Number(laborOperation.total),
                        hours: Number(laborOperation.timeLabour),
                      }));
                    }
                    return [...gtInfos, ...laborOperations, ...partInfos];
                  }, [])
                : [];
            return [...acc, ...infos];
          }, [])
      : [];
  // all Tmo
  const arrayTmo =
    defects && defects.length > 0
      ? defects
          .filter((defect) => defect.labourTimeInfos && defect.labourTimeInfos.length > 0)
          .reduce((acc, item) => {
            const labourTimeInfos = item.labourTimeInfos.map((labourTimeInfo) => ({
              type: labourTimeInfo.type,
              hours: labourTimeInfo.computedLabourCost !== 0 ? Number(labourTimeInfo.hours) : 0,
              total: labourTimeInfo.computedLabourCost,
              privateCostDepreciation: item.privateCostDepreciation,
              publicCostDepreciation: item.publicCostDepreciation,
            }));
            return [...acc, ...labourTimeInfos];
          }, [])
      : [];

  // all Forfait
  const arrayForfait =
    defects && defects.length > 0
      ? defects
          .filter(
            (defect) =>
              (!defect.labourTimeInfos || (defect.labourTimeInfos && defect.labourTimeInfos.length === 0)) && !defect.gtRepairCostInfo && (defect.privateCost || defect.publicCost),
          )
          .map((ckpoint) => ({
            privateCost: ckpoint.privateCost,
            publicCost: ckpoint.publicCost,
            privateCostDepreciation: ckpoint.privateCostDepreciation,
            publicCostDepreciation: ckpoint.publicCostDepreciation,
            type: 'Forfait',
          }))
      : [];

  const arrayDefects = [...arrayTmo, ...arrayForfait, ...arrayGt];

  // group by type and sum all total
  const sumDefects =
    arrayDefects &&
    arrayDefects.length > 0 &&
    _(arrayDefects)
      .groupBy('type')
      .reduce((acc, el) => {
        const hours = el.filter((current) => current.hours).map((current) => current.hours);
        const grossPrices = [];
        const netPrices = [];

        el.forEach((current) => {
          let depreciation = 0;
          let netPrice = 0;
          let grossPrice = 0;

          if (current.privateCostDepreciation || current.publicCostDepreciation) {
            depreciation = !checked ? current.privateCostDepreciation : current.publicCostDepreciation;
          }

          // type Forfait
          if (current.type === 'Forfait') {
            if (current.privateCostDepreciation || current.publicCostDepreciation) {
              depreciation = !checked ? current.privateCostDepreciation : current.publicCostDepreciation;
            }
            if (current.privateCost || current.publicCost) {
              // eslint-disable-next-line no-nested-ternary
              netPrice = !checked && current.privateCost ? current.privateCost : checked && current.publicCost ? current.publicCost : 0;
              grossPrice = netPrice / ((100 - depreciation) / 100);
            }
            // type TMO and GT
          } else if (current.total) {
            grossPrice = current.total;
            // if depre === 0, price not depreciated
            netPrice = depreciation > 0 ? grossPrice - (depreciation * grossPrice) / 100 : grossPrice;
          }
          if (current.type !== 'Forfait' && current.hours === 0) return;
          grossPrices.push(grossPrice);
          netPrices.push(netPrice);
        });

        const sumHours = hours && hours.length > 0 ? sum(hours) : 0;
        const sumGrossCost = grossPrices && grossPrices.length > 0 ? sum(grossPrices) : [];
        const sumNetCost = netPrices && netPrices.length > 0 ? sum(netPrices) : [];

        acc.push({
          type: el[0].type,
          sumHours,
          sumGrossCost,
          sumNetCost,
        });
        return acc;
      }, []);

  const identificationBlock = () => (
    sectionIdentification && (
      <>
        <Identification
          section={sectionIdentification}
          type={type}
          showCheckpoint={showCheckpoint}
          navigatorLanguage={navigatorLanguage}
          canEdit={canEdit}
          canSeeFullReport={canSeeFullReport}
          pdfView={pdfView}
        />
      </>
    )
  );

  const photoBlock = () => (
    sectionPhoto && (
      <div className='left__block viewer-photos left__block--no-background p0'>
        <PhotoSynthesis
          section={sectionPhoto}
          type={type}
          showCheckpoint={showCheckpoint}
          openLightbox={openLightbox}
          openImage={openImage}
          navigatorLanguage={navigatorLanguage}
          canEdit={canEdit}
          pdfView={pdfView}
        />
      </div>
    )
  );


  const optionBlock = () => (
    sectionOpt && (
      <>
        <Option
          section={sectionOpt}
          type={type}
          showCheckpoint={showCheckpoint}
          navigatorLanguage={navigatorLanguage}
          canEdit={canEdit}
          canSeeFullReport={canSeeFullReport}
          pdfView={pdfView}
        />
      </>
    )
  );

  // Method for the creation of the viewer's normal view
  const synthesisBlock = () => (
    <Col xs={12} md={4} className='viewer__synthesis__left left' key={1}>
      {identificationBlock()}
      {photoBlock()}
      {optionBlock()}
    </Col>
  );

  // Method for creating the PDF view
  const pdfFormatDisplay = () => (
    <>
      <Col xs={12} md={4} className='viewer__synthesis__left left' key={1}>
        {identificationBlock()}
      </Col>
      {damagesBlock()}
      <Col xs={12} md={4} className='viewer__synthesis__left left' key={3}>
        {photoBlock()}
        {optionBlock()}
      </Col>
    </>
  )

  const damagesBlock = () => (
    <Col xs={12} md={8} className='viewer__synthesis__right right' key={2}>
      <div className='right__block block'>
        <div className='card item'>
          <div className='title'>
            <Text className='blueTitle'>{_.get(constants, `lang.${navigatorLanguage}.damages`)}</Text>
            {canEdit && sectionTitle && sectionFre && sectionFre.length > 0 && (
              <div className='--hide-from-pdf right__block'>
                <Card
                  isConcession={isConcession}
                  section={sectionTitle}
                  titleClass='blueTitle regular mr-2'
                  checkpointId={1}
                  onChangeFrevo={onChangeFrevo}
                  checked={checked}
                  navigatorLanguage={navigatorLanguage}/>
              </div>
            )}
          </div>
        </div>
        <div className='divider' />
        {(!sectionFre || (sectionFre && sectionFre.length === 0)) && (
          <div className='block__content card item container'>
            <p className='blackValue center'>{_.get(constants, `lang.${navigatorLanguage}.noDamages`)}</p>
          </div>
        )}
        {sectionFre &&
        sectionFre.length > 0 &&
        sectionFre.map((section, key) => (
          <div key={key} className='block__content card item container'>
            <Damages
              section={section}
              checked={checked}
              shownPrices={shownPrices}
              shownTotal={shownTotal}
              type={type}
              showCheckpoint={showCheckpoint}
              navigatorLanguage={navigatorLanguage}
              canEdit={canEdit}
              adminCanEdit={adminCanEdit}
              openImage={openImage}
              pdfView={pdfView}
            />
          </div>
        ))}
        {sectionFre && sectionFre.length > 0 && shownTotal && totalTtc && (
          <div className='block__content card item container'>
            <Total label={_.get(constants, `lang.${navigatorLanguage}.total`)} value={totalTtc}>
              {sumDefects && sumDefects.length > 0 ? (
                <table id='labourTimeInfos' className='mb8'>
                  <thead>
                  <tr>
                    <th />
                    <th>{_.get(constants, `lang.${navigatorLanguage}.laborPriceHour`)}</th>
                    <th>{_.get(constants, `lang.${navigatorLanguage}.laborTime`)}</th>
                    <th className='price'>{_.get(constants, `lang.${navigatorLanguage}.laborGrossCost`)}</th>
                    <th>{_.get(constants, `lang.${navigatorLanguage}.depreciation`)}</th>
                    <th className='price'>{_.get(constants, `lang.${navigatorLanguage}.laborNetCost`)}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {sumDefects.map((defect, defectKey) => {
                    const laborTime = defect.sumHours > 0 ? convertFloatToTime(defect.sumHours).label : 0;
                    const depreciation = getDepreciationInGeneralTotalTable(defect.sumGrossCost, defect.sumNetCost)
                      ? `${getDepreciationInGeneralTotalTable(defect.sumGrossCost, defect.sumNetCost).toFixed(2)} %`
                      : '-';
                    return (
                      <tr key={defectKey}>
                        <td>{translateTechnicalType(defect.type, navigatorLanguage)}</td>
                        <td>-</td>
                        <td>{laborTime !== 0 ? laborTime : '-'}</td>
                        <td>{defect.sumGrossCost && defect.sumGrossCost > 0 ? `${defect.sumGrossCost.toFixed(2)} €` : '-'}</td>
                        <td>{depreciation}</td>
                        <td>{defect.sumNetCost && defect.sumNetCost > 0 ? `${defect.sumNetCost.toFixed(2)} €` : '-'}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              ) : (
                []
              )}
            </Total>
          </div>
        )}
      </div>
    </Col>
  );

  // This manipulation is to display the damage as a priority when generating the pdf with the Sejda API
  const body = pdfView ? pdfFormatDisplay() : [synthesisBlock(), damagesBlock()];

  return (
    <div>
      <Row>
        { body }
      </Row>
    </div>
  );
};

Synthesis.propTypes = {
  sectionIdentification: PropTypes.object,
  sectionOpt: PropTypes.object,
  sectionPhoto: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionFre: PropTypes.array,
  sectionTotal: PropTypes.object,
  checked: PropTypes.bool,
  shownTotal: PropTypes.bool,
  showCheckpoint: PropTypes.func,
  type: PropTypes.string,
  openImage: PropTypes.func,
  openLightbox: PropTypes.func,
  navigatorLanguage: PropTypes.string,
  hasLabel: PropTypes.bool,
  noHide: PropTypes.bool,
  enableHover: PropTypes.bool,
  classNames: PropTypes.string,
  isConcession: PropTypes.bool,
  onChangeFrevo: PropTypes.func,
  canEdit: PropTypes.bool,
  canSeeFullReport: PropTypes.bool,
  pdfView: PropTypes.string,
};

export default Synthesis;
