import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Section from '../Section';
import Text from '../Text';
import Svg from '../Svg';
import constants from '../../constants';

const Option = ({
  section, type, showCheckpoint, navigatorLanguage, canEdit, canSeeFullReport, pdfView,
}) => {
  const { checkpoints } = section;
  const [collapse, setCollapse] = useState(true);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <div className="left__block block">
      <Row className="middle-xs">
        <Col xs={6}>
          <div className='card item'>
            <div className="title">
              <Text className='blueTitle'>{_.get(constants, `lang.${navigatorLanguage}.equipments`)}</Text>
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <Row className="--hide-from-pdf end-xs">
            <Col xs={9} md={10}>
              <div className="block__text">
                <span className="blueTitle regular">{_.get(constants, `lang.${navigatorLanguage}.seeOptions`)}</span>
              </div>
            </Col>
            <Col xs={2} md={2} className="flex-center">
              <button className="button-collapse button-collapse--blue" onClick={handleCollapse}>
                <Svg icon="arrow-down" color="white" collapse={collapse} />
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={`collapse-content ${collapse ? 'collapsed' : ''}`}>
        <div className="divider"/>
        <Section
          section={section}
          checkpoints={checkpoints}
          type={type}
          showCheckpoint={showCheckpoint}
          navigatorLanguage={navigatorLanguage}
          canEdit={canEdit}
          canSeeFullReport={canSeeFullReport}
          pdfView={pdfView}
        />
      </div>
    </div>
  );
};

Option.propTypes = {
  section: PropTypes.object,
  navigatorLanguage: PropTypes.string,
  showCheckpoint: PropTypes.func,
  type: PropTypes.string,
  canEdit: PropTypes.bool,
  pdfView: PropTypes.string,
};

export default Option;
