export const addOrUpdateObjects = (array1, array2, key) => {
  array2.forEach(obj => {
    const index = array1.findIndex(item => item[key] === obj[key]);
    if (index === -1) {
      array1.push(obj);
    } else {
      array1[index] = obj;
    }
  });
  return array1;
};
