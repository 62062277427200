import _ from 'lodash';
import constants from '../constants';

const getValueLabel = (defect, translations, lang) => {
  const { kind, value } = defect;
  if (
    ((kind === 'CommentCheckpoint' || kind === 'DateCheckpoint' || kind === 'TextCheckpoint') && !value) ||
    (kind === 'BoolCheckpoint' && value === '' && !defect.answerCategory)
  ) {
    return '';
  }

  if (value === true && kind === 'BoolCheckpoint') {
    return _.get(constants, `lang.${lang}.valueLabel.yes`);
  }

  if ((!value || value === false) && kind === 'BoolCheckpoint') {
    return _.get(constants, `lang.${lang}.valueLabel.no`);
  }

  if (kind === 'EnumCheckpoint' && value) {
    let label;
    const detail = _.find(defect.details.list, { key: value });

    if (detail?.labelTranslation && translations[detail.labelTranslation]) {
      if (Object.keys(translations).length > 0) {
        label = translations[detail.labelTranslation][lang];
      } else {
        label = detail.labelTranslation[lang];
      }
    } else {
      // Fallback to non translated
      label = detail?.label;
    }
    return label || value;
  }
  return value;
};

const defectCheckpointPopulate = (summary, defectCheckpoint) => {
  const defectRepairCost =
    _.get(summary, 'repairCost.defects') && _.get(summary, 'repairCost.defects').find((elem) => _.get(elem, 'checkpoint.refCheckpoint') === defectCheckpoint._id);
  const { publicCost, publicCostMax, publicCostDepreciation, privateCost, privateCostMax, privateCostDepreciation, gtRepairCostInfo, labourTimeInfos, comment } =
    defectRepairCost || {};

  return {
    ...defectCheckpoint,
    ...(publicCost && { publicCost }),
    ...(publicCostMax && { publicCostMax }),
    ...(publicCostDepreciation && { publicCostDepreciation }),
    ...(privateCost && { privateCost }),
    ...(privateCostMax && { privateCostMax }),
    ...(privateCostDepreciation && { privateCostDepreciation }),
    labourTimeInfos,
    gtRepairCostInfo,
    comment,
    defectId: _.get(defectRepairCost, '_id'),
  };
};

const findDefectFreCheckpoint = (defectFreCheckpoint, anomaly) => {
  const commonCondition =
    anomaly.defectTypologyId === _.get(defectFreCheckpoint, 'defect.partVehicle') &&
    anomaly.categoryId === _.get(defectFreCheckpoint, 'defect.category') &&
    anomaly.defectId === _.get(defectFreCheckpoint, 'defect.valueDefect') &&
    !anomaly.found;

  if (anomaly.repairOperationId === _.get(defectFreCheckpoint, 'repairOperationId')) {
    return commonCondition && anomaly.repairOperationId === _.get(defectFreCheckpoint, 'repairOperationId');
  }
  return commonCondition;
};

const defectFreCheckpointPopulate = (defectFreCheckpoint, allAnomalies) => {
  let anomaly = _.find(allAnomalies, (ano) => findDefectFreCheckpoint(defectFreCheckpoint, ano));
  // We keep track of found anomaly as same ano would be returned twice
  if (anomaly) anomaly.found = true;

  const { publicCost, publicCostMax, publicCostDepreciation, privateCost, privateCostMax, privateCostDepreciation, labourTimeInfos, gtRepairCostInfo, comment, severityCm } =
    defectFreCheckpoint || {};
  return {
    element: anomaly.element,
    damageType: anomaly.damageType,
    reparationType: anomaly.reparationType,
    label: anomaly.label,
    labelTranslation: anomaly.labelTranslation,
    PartOfVehicleLabel: anomaly.PartOfVehicleLabel,
    repairCategory: anomaly.repairCategory,
    photos: anomaly.photos || [anomaly.photo],
    ...(publicCost && { publicCost }),
    ...(publicCostMax && { publicCostMax }),
    ...(publicCostDepreciation && { publicCostDepreciation }),
    ...(privateCost && { privateCost }),
    ...(privateCostMax && { privateCostMax }),
    ...(privateCostDepreciation && { privateCostDepreciation }),
    labourTimeInfos,
    gtRepairCostInfo,
    comment,
    repairOperationId: anomaly.repairOperationId,
    severityCm,
    defectId: defectFreCheckpoint._id,
    category: anomaly.category
  };
};

// order by vehicle's parts and sort by price
const groupAndSort = (checkpoints) =>
  _.chain(checkpoints)
    .groupBy(({ category, element, damageType }) => category || element || damageType || '')
    .map((checkpointsGrouped) => ({
      price:
        _.get(
          checkpointsGrouped.find((checkpoint) => checkpoint.publicCost),
          'publicCost',
        ) || 0,
      checkpointsGrouped,
    }))
    .value()
    .reduce((allCheckpoints, checkpointsSerialized) => [...allCheckpoints, ...checkpointsSerialized.checkpointsGrouped], []);

const orderByCategory = (a, b) => {
  if ((a.category === b.category) && (a.element === b.element)) {
    // damageType is only important when element are the same
    return a.damageType > b.damageType ? 1 : -1;
  }
};

const orderByPrice = (a, b) => {
  if ((a.element === b.element) && (a.damageType === b.damageType)) {
    // publicCost is only important when element and damage type are the same
    if (typeof a.publicCost === 'undefined') return 1;
    if (typeof b.publicCost === 'undefined') return -1;
    return a.publicCost - b.publicCost;
  }
};

const createHighlightSections = (repairCostSections, summary, lang) => {
  const allCheckpoints = repairCostSections.length > 0 ? [].concat(...repairCostSections.map((section) => section.checkpoints)) : [];
  const allAnomalies = allCheckpoints.length > 0 ? [].concat(...allCheckpoints.filter((checkpoint) => checkpoint.anomalies).map((checkpoint) => checkpoint.anomalies)) : [];

  const highlightDefects =
    allCheckpoints.length > 0
      ? allCheckpoints
          .filter((checkpoint) => _.find(_.get(checkpoint, 'detailsList'), { key: checkpoint.value, highlight: true }))
          .map((defectCheckpoint) => defectCheckpointPopulate(summary, defectCheckpoint))
      : [];

  // pings anomalies
  const defectFreCheckpoints = _.get(summary, 'repairCost.defects')
    ? _.get(summary, 'repairCost.defects')
        .filter((checkpoint) => checkpoint.kind === 'DefectFRE')
        .map((defectFreCheckpoint) => defectFreCheckpointPopulate(defectFreCheckpoint, allAnomalies, lang))
    : [];

  const boolCheckpoints =
    allCheckpoints.length > 0
      ? allCheckpoints
          .filter((checkpoint) => checkpoint.kind === 'BoolCheckpoint' && checkpoint.value && _.get(checkpoint, 'detailsHighlight') === checkpoint.value)
          .map((defectCheckpoint) => defectCheckpointPopulate(summary, defectCheckpoint))
      : [];
  const commentCheckpoints = allCheckpoints.length > 0 ? allCheckpoints.filter((checkpoint) => checkpoint.kind === 'CommentCheckpoint' && checkpoint.value) : [];

  const allDefects = [...highlightDefects, ...defectFreCheckpoints, ...commentCheckpoints, ...boolCheckpoints];
  const carrosserieCheckpoints = allDefects.filter((defect) => defect.repairCategory === 'CARROSSERIE');
  const mecaniqueCheckpoints = allDefects.filter((defect) => defect.repairCategory === 'MECANIQUE');
  const autresCheckpoints = allDefects.filter((defect) => defect.repairCategory === 'AUTRES');
  return [
    ...(carrosserieCheckpoints.length
      ? [
          {
            sectionName: constants.CARROSSERIE.name,
            category: 'CARROSSERIE',
            categoryTranslation: _.get(constants, `CARROSSERIE.categoryTranslation.${lang}`),
            checkpoints: groupAndSort(carrosserieCheckpoints).sort(orderByCategory).sort(orderByPrice),
            sectionKind: 'REPAIR_COST_CATEGORY',
            totalPublic: { min: _.get(summary, 'repairCost.totalBody.costPublic'), max: _.get(summary, 'repairCost.totalBody.maxCostPublic') },
            totalPrivate: { min: _.get(summary, 'repairCost.totalBody.costPrivate'), max: _.get(summary, 'repairCost.totalBody.maxCostPrivate') },
          },
        ]
      : []),
    ...(mecaniqueCheckpoints.length
      ? [
          {
            sectionName: constants.MECANIQUE,
            category: 'MECANIQUE',
            categoryTranslation: _.get(constants, `MECANIQUE.categoryTranslation.${lang}`),
            checkpoints: groupAndSort(mecaniqueCheckpoints).sort(orderByCategory).sort(orderByPrice),
            sectionKind: 'REPAIR_COST_CATEGORY',
            totalPublic: { min: _.get(summary, 'repairCost.totalMechanical.costPublic'), max: _.get(summary, 'repairCost.totalMechanical.maxCostPublic') },
            totalPrivate: { min: _.get(summary, 'repairCost.totalMechanical.costPrivate'), max: _.get(summary, 'repairCost.totalMechanical.maxCostPrivate') },
          },
        ]
      : []),
    ...(autresCheckpoints.length
      ? [
          {
            sectionName: constants.AUTRES,
            category: 'AUTRES',
            categoryTranslation: _.get(constants, `AUTRES.categoryTranslation.${lang}`),
            checkpoints: groupAndSort(autresCheckpoints).sort(orderByCategory).sort(orderByPrice),
            sectionKind: 'REPAIR_COST_CATEGORY',
            totalPublic: { min: _.get(summary, 'repairCost.totalOther.costPublic'), max: _.get(summary, 'repairCost.totalOther.maxCostPublic') },
            totalPrivate: { min: _.get(summary, 'repairCost.totalOther.costPrivate'), max: _.get(summary, 'repairCost.totalOther.maxCostPrivate') },
          },
        ]
      : []),
  ];
};
const concatLabel = (checkpoint, anomaly, translations, options = { lang: 'fr', valueToReturn: '' }) => {
  const firstPart = translations[checkpoint.labelTranslation] ? translations[checkpoint.labelTranslation][options.lang] : checkpoint.label;
  const labelTranslationSecondPart = translations[checkpoint['defects'][anomaly.defectTypologyId]['categories'][anomaly.categoryId].labelTranslation];
  const secondPart = labelTranslationSecondPart
    ? labelTranslationSecondPart[options.lang]
    : checkpoint['defects'][anomaly.defectTypologyId]['categories'][anomaly.categoryId].label;
  const labelTranslationThirdPart = translations[checkpoint['defects'][anomaly.defectTypologyId]['categories'][anomaly.categoryId]['defects'][anomaly.defectId].labelTranslation];
  const thirdPart = labelTranslationThirdPart
    ? labelTranslationThirdPart[options.lang]
    : checkpoint['defects'][anomaly.defectTypologyId]['categories'][anomaly.categoryId]['defects'][anomaly.defectId].label;
  const repairOperations = checkpoint['defects'][anomaly.defectTypologyId]['repairOperations'];
  const labelTranslationRepairOperation =
    repairOperations && repairOperations.filter((el) => el.repairOperationId === anomaly.repairOperationId).map((res) => res.labelTranslation)[0];
  const labelTranslationRepairOperationId = labelTranslationRepairOperation && translations[labelTranslationRepairOperation];
  const repairOperationId = labelTranslationRepairOperationId ? labelTranslationRepairOperationId[options.lang] : anomaly.repairOperationId;

  switch (options.valueToReturn) {
    case 'element':
      return firstPart;
    case 'damageType':
      return secondPart;
    case 'reparationType':
      return thirdPart;
    case 'repairOperationId':
      return repairOperationId;
    case 'subLabel':
      return secondPart + ' / ' + thirdPart;
    case 'PartOfVehicleLabel':
      return firstPart + ' / ' + secondPart;
    default:
      return firstPart + ' / ' + secondPart + ' / ' + thirdPart;
  }
};

const photoSectionPopulate = (checkpoint, translations, lang) => {
  const { refCheckpoint, hideInViewer } = checkpoint;
  const newAnomalies =
    refCheckpoint.defects &&
    _.map(refCheckpoint.anomalies, (anomaly) => {
      return {
        label: concatLabel(refCheckpoint, anomaly, translations, { lang }),
        element: concatLabel(refCheckpoint, anomaly, translations, { lang, valueToReturn: 'element' }),
        damageType: concatLabel(refCheckpoint, anomaly, translations, { lang, valueToReturn: 'damageType' }),
        reparationType: concatLabel(refCheckpoint, anomaly, translations, { lang, valueToReturn: 'reparationType' }),
        labelTranslation: concatLabel(refCheckpoint, anomaly, translations, { lang }),
        subLabel: concatLabel(refCheckpoint, anomaly, translations, { lang, valueToReturn: 'subLabel' }),
        PartOfVehicleLabel: concatLabel(refCheckpoint, anomaly, translations, { lang, valueToReturn: 'PartOfVehicleLabel' }),
        repairCategory: _.get(refCheckpoint, 'category.repairCategory'),
        category: translations[refCheckpoint.category.labelTranslation] ? translations[refCheckpoint.category.labelTranslation][lang] : _.get(refCheckpoint, 'category.label'),
        ...anomaly,
        repairOperationId: concatLabel(refCheckpoint, anomaly, translations, { lang, valueToReturn: 'repairOperationId' }),
      };
    });
  return {
    _id: refCheckpoint._id,
    anomalies: newAnomalies,
    defectsList: _.get(refCheckpoint, 'defects'),
    kind: _.get(refCheckpoint, 'kind'),
    label: refCheckpoint.label,
    allTranslations: refCheckpoint.labelTranslation,
    labelTranslation: translations[refCheckpoint.labelTranslation] ? translations[refCheckpoint.labelTranslation][lang] : refCheckpoint.label,
    repairCategory: _.get(refCheckpoint, 'category.repairCategory'),
    valueLabel: getValueLabel(refCheckpoint, translations, lang),
    value: _.get(refCheckpoint, 'value'),
    uri: _.get(refCheckpoint, 'uri'),
    hideInViewer: hideInViewer,
  };
};

const sectionPopulate = ({ refCheckpoint, hideInViewer }, translations, lang) => {
  const conditionalPhotoLabelTranslation = translations[refCheckpoint['details']?.addFieldContent?.map((el) => el.labelTranslation)[0]];

  return {
    _id: refCheckpoint._id,
    kind: _.get(refCheckpoint, 'kind'),
    label: refCheckpoint.label,
    element: refCheckpoint.element,
    damageType: refCheckpoint.damageType,
    reparationType: refCheckpoint.reparationType,
    labelTranslation: translations[refCheckpoint.labelTranslation] ? translations[refCheckpoint.labelTranslation][lang] : refCheckpoint.label,
    allTranslations: refCheckpoint.labelTranslation,
    category: translations[refCheckpoint.category.labelTranslation] ? translations[refCheckpoint.category.labelTranslation][lang] : _.get(refCheckpoint, 'category.label'),
    repairCategory: _.get(refCheckpoint, 'category.repairCategory'),
    detailsList: _.get(refCheckpoint, 'details.list'),
    detailsHighlight: _.get(refCheckpoint, 'details.highlight'),
    valueLabel: getValueLabel(refCheckpoint, translations, lang),
    value: _.get(refCheckpoint, 'value'),
    photos: _.get(refCheckpoint, 'photos'),
    conditionalPhotoLabel: conditionalPhotoLabelTranslation ? conditionalPhotoLabelTranslation[lang] : _.get(refCheckpoint, 'details.addFieldContent[0].label'),
    key: refCheckpoint.specialType && _.get(refCheckpoint.specialType, 'key'),
    hideInViewer: hideInViewer,
  };
};

const parseElement = (sections, summary, translations, viewerConfig, lang) => {
  // Allows for the propagation of hideInViewer to triggered cond checkpoints
  const hiddenAddFieldCheckpoints = [];

  for (const section of sections) {
    for (const checkpoint of section?.checkpoints) {
      if (checkpoint?.hideInViewer && checkpoint?.refCheckpoint?.details?.addFieldContent?.length) {
        hiddenAddFieldCheckpoints.push(...checkpoint.refCheckpoint.details.addFieldContent.map((addFieldCheckpoint) => addFieldCheckpoint._id));
      }
    }
  }

  // create sections populating necessary data
  const newSections = sections.map((section) => {
    let sectionNameTranslation = '';
    // Reformat checkpoints structure for future display & processing
    const reformattedCheckpoints = section.checkpoints
      .filter((checkpoint) => checkpoint?.refCheckpoint?._id && checkpoint?.refCheckpoint?.kind !== 'DividerCheckpoint')
      .map((checkpoint) => {
        if (hiddenAddFieldCheckpoints.includes(checkpoint.refCheckpoint._id)) {
          checkpoint.hideInViewer = true;
        }

        if (_.get(checkpoint, 'refCheckpoint.kind') === 'PhotoCheckpoint') {
          return photoSectionPopulate(checkpoint, translations, lang);
        }
        return sectionPopulate(checkpoint, translations, lang);
      });

    if (Object.keys(translations).length > 0) {
      if (section?.sectionNameTranslation && translations[section?.sectionNameTranslation]) {
        sectionNameTranslation = translations[section?.sectionNameTranslation][lang];
      } else {
        // Fallback to non translated
        sectionNameTranslation = sections?.label;
      }
    } else {
      sectionNameTranslation = section?.sectionNameTranslation[lang];
    }
    return { ...section, sectionNameTranslation, checkpoints: reformattedCheckpoints };
  });
  const highlightSections = createHighlightSections(newSections, summary, lang);

  // Display photoScan section first (just after defects recap)
  if (_.findIndex(newSections, 'photoScan')) {
    newSections.unshift(..._.remove(newSections, 'photoScan'));
  }

  const addGtotal = {
    sectionKind: constants.TOTAL.kind,
    sectionName: constants.TOTAL.name,
    sectionNameTranslation: _.get(constants, `TOTAL.nameTranslation.${lang}`),
    totalPublic: { min: _.get(summary, 'repairCost.totalGeneral.costPublic'), max: _.get(summary, 'repairCost.totalGeneral.maxCostPublic') },
    totalPrivate: { min: _.get(summary, 'repairCost.totalGeneral.costPrivate'), max: _.get(summary, 'repairCost.totalGeneral.maxCostPrivate') },
    noTrigger: true,
    gTotal: true,
    header: true,
    sectionTitleHidden: true,
  };

  if (addGtotal.totalPrivate.min === 0 && addGtotal.totalPrivate.max === 0 && addGtotal.totalPublic.min === 0 && addGtotal.totalPublic.max === 0) {
    return [...(!viewerConfig.includes('excludeIntroCost') ? highlightSections : []), ...newSections];
  }

  const firstSection = {
    header: true,
    kind: constants.TITLE.kind,
    sectionName: constants.TITLE.name,
    sectionNameTranslation: _.get(constants, `TITLE.nameTranslation.${lang}`),
  };

  return [
    ...(_.difference(['noTotal', 'noPrices'], viewerConfig).length !== 0 && !viewerConfig.includes('excludeIntroCost') ? [firstSection] : []),
    ...(!viewerConfig.includes('excludeIntroCost') ? highlightSections : []),
    ...(!viewerConfig.includes('noTotal') && !viewerConfig.includes('excludeIntroCost') ? [addGtotal] : []),
    ...newSections,
  ];
};

export default parseElement;
