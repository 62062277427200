import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../Text';
import ViewAnomaly from '../../ViewAnomaly';
import ViewCarousel from '../../ViewCarousel';
import ViewAnomalyPdf from '../../ViewAnomalyPdf';

const Picture = ({ checkpoint, damagePicture }) => {
  return (
    <>
      {checkpoint.photos && (
        <div className='image-container'>
          { damagePicture
            ? (<ViewAnomalyPdf photosCheckpoint={checkpoint.photos ? checkpoint.photos : [checkpoint.photo]} />)
            : (
              <ViewAnomaly
                noHide
                enableHover
                onHover={
                  <div className='anomalyContainer fre'>
                    <div className='title'>
                      <Text className='greyLabel weight200'>{checkpoint.photos.length > 1 ? _.get(checkpoint, 'conditionalPhotoLabel') : _.get(checkpoint, 'label')}</Text>
                    </div>

                    <div>
                      <ViewCarousel damagesView={true} photosCheckpoint={checkpoint.photos ? checkpoint.photos : [checkpoint.photo]} />
                    </div>
                  </div>
                }>
                <Text className='priceTotal'>
                  <i className='fas fa-camera' style={{ fontSize: 24 }} />
                </Text>
              </ViewAnomaly>
            )
          }
        </div>
      )}
    </>
  );
};

Picture.propTypes = {
  checkpoint: PropTypes.object,
  openImage: PropTypes.func,
  damagePicture: PropTypes.string,
};

export default Picture;
