import axios from 'axios';

const config = {api: {}};

switch (process.env.REACT_APP_ENV) {
  case "local":
    config.api.url = "http://localhost:8081";
    break;
  case "development":
    config.api.url = "https://api-dev.monspecialisteauto.com";
    break;
  case "pre-prod":
    config.api.url = "https://api-pre-prod.monspecialisteauto.com";
    break;
  case "production":
    config.api.url = "https://api.monspecialisteauto.com";
    break;
  default:
    config.api.url = "http://localhost:8081";
    break;
}

const fetch = ({
  method, url, data, withCredentials,
}) => new Promise((resolve, reject) => {
  axios({
    method,
    url: config.api.url + url,
    data,
    withCredentials,
  })
    .then((res) => resolve(res.data))
    .catch((err) => {
      const resErr = err && err.response ? err.response.data : {};
      return reject(resErr);
    });
});

const Api = {
  getInspection: (data) => new Promise((resolve, reject) => {
    fetch({
      method: 'GET',
      url: `/api/v3/inspections/report/${data.inspectionId}`,
      data,
      withCredentials: true,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  updateDefect: (inspectionId, defectId, data) => new Promise((resolve, reject) => {
    fetch({
      method: 'PUT',
      url: `/api/v3/inspections/report/${inspectionId}/editViewerRepairCost/${defectId}`,
      data,
      withCredentials: true,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  addAndUpdateDefect: (inspectionId, freToAddOrUpdate) => new Promise((resolve, reject) => {
    fetch({
      method: 'PUT',
      url: `/api/v3/inspections/report/${inspectionId}/addOrUpdateViewerRepairCost`,
      data: {defects: freToAddOrUpdate},
      withCredentials: true,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

};

export default Api;
