import PropTypes from 'prop-types';
import React from 'react';
import Text from './Text';
import ViewAnomaly from './ViewAnomaly';
import ViewCarousel from './ViewCarousel';

const ImageViewer = ({
  anomalies = [],
  element,
  elementKey,
  imgRef,
  openLightbox = () => {},
  updateDimensions = () => {},
  isOpen,
  iconZoom,
  handleOpen = () => {},
  noHide = false,
  enableHover = true,
  classNames,
  children,
  handlerPropagation,
  pdfView,
}) => {
  const renderViewCarousel = (anomaly) => {
    if (handlerPropagation) {
      return <ViewCarousel carouselView={true} handlerPropagation={handlerPropagation} photosCheckpoint={anomaly?.photos ? anomaly.photos : [anomaly.photo]} />;
    }
    return <ViewCarousel photosCheckpoint={anomaly?.photos ? anomaly.photos : [anomaly.photo]} />;
  };

  return (
    <div className='image-container'>
      {anomalies &&
        anomalies.map((anomaly, anomalyKey) => (
          <ViewAnomaly
            key={anomalyKey}
            noHide={noHide}
            enableHover={enableHover}
            onHover={
              <div key={`${anomalyKey}-viewer`} className='anomalyContainer' style={anomaly.orientation}>
                <div className='title'>
                  <Text className='greyLabel weight200'>{anomaly.subLabel}</Text>
                </div>
                <div>{renderViewCarousel(anomaly)}</div>
                {anomaly.comment && (
                  <div className='desc'>
                    <Text className='blackLabel weight200'>Observation :</Text>
                    <Text className='greyLabel weight500'>{anomaly.comment}</Text>
                  </div>
                )}
              </div>
            }>
            <div
              className={`anomalyDot ${classNames}`}
              style={{
                left: anomaly.x,
                top: anomaly.y,
              }}
            />
          </ViewAnomaly>
        ))}
      <div className='center'>
        {children}
        <img
          className='image-container__img'
          ref={imgRef}
          src={pdfView ? `https://92g2xsphz7.execute-api.eu-west-3.amazonaws.com/prod/images/resize?url=${element.uri}&amp;width=1000` : element.uri}
          alt='fw'
          width={`${isOpen ? '' : '100%'}`}
          id={`${elementKey}`}
          onClick={() => {
            openLightbox(element.uri);
            handleOpen('open');
          }}
          onLoad={updateDimensions}
        />
      </div>
      {!isOpen && iconZoom && (
        <div
          onClick={() => {
            openLightbox(element.uri);
            handleOpen('open');
          }}
          className={`--hide-from-pdf icon-zoom ${elementKey === 0 && 'icon-zoom--fixed'}`}>
          <i className='fas fa-search-plus' />
        </div>
      )}
    </div>
  );
};

ImageViewer.propTypes = {
  element: PropTypes.object,
  elementKey: PropTypes.number,
  openLightbox: PropTypes.func,
  updateDimensions: PropTypes.func,
  openAnomaly: PropTypes.func,
  anomalies: PropTypes.array,
  imgRef: PropTypes.object,
  isOpen: PropTypes.bool,
  iconZoom: PropTypes.bool,
  handleOpen: PropTypes.func,
  noHide: PropTypes.bool,
  enableHover: PropTypes.bool,
  classNames: PropTypes.string,
  children: PropTypes.node,
  handlerPropagation: PropTypes.func,
  pdfView: PropTypes.string,
};
export default ImageViewer;
