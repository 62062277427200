export const getRightReportPdf = (documentList, shownPrices, canSeeFullReport, lang) => {
  const documentIndexes = {
    true: {
      true: 0, // shownPrices is true and canSeeFullReport is true => PDF_Report_Intern
      false: 1 // shownPrices is true and canSeeFullReport is false => PDF_Report_Extern
    },
    false: {
      true: 2, // shownPrices is false and canSeeFullReport is true => PDF_Report_No_Cost_Intern
      false: 3 // shownPrices is false and canSeeFullReport is false => PDF_Report_No_Cost_Extern
    }
  };
  const index = documentIndexes[shownPrices][canSeeFullReport];
  return documentList.filter(document => document?.lang === lang)[index]?.url;
};
