import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-grid-system';

const Total = ({ label, value, children }) => (<div>
    {label && value && (value.min !== undefined || value.max !== undefined) && <div className="frevo-total">
      {children}
      <Row justify="end">
        <Col xs={7} md={5} style={{ textAlign: 'right' }}>
          <span className="blueTitle font20 condensed">{label} HT</span>
        </Col>
        {(!value.max || value.max === 0) && <Col xs={3} md={3} className="frevo-total__value textRight">
          <span className="priceTotal font20">: {value.min.toFixed(2)} €</span>
        </Col>}
        {value.max !== 0 && <Col xs={3} md={3} className="frevo-total__value textRight">
          <span className="priceTotal font20">: {value.min.toFixed(2)} - {value.max.toFixed(2)} €</span>
        </Col>}
      </Row>
    </div>}
  </div>);

Total.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
  value: PropTypes.object,
};

export default Total;
