import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { ToastProvider } from 'react-toast-notifications'; // eslint no-import/no-unresolved

const ES6Promise = require('es6-promise');
ES6Promise.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <App />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

