const _ = require('lodash');

const prepareConfig = (viewerConfig) => {
  if (!viewerConfig) {
    return [];
  }

  const configs = viewerConfig.split(';');

  return _.pull(configs, '', undefined, null);
};

export default prepareConfig;
