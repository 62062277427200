import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class NotFound extends React.Component {
  componentDidMount() {
    window.location.replace('https://monspecialisteauto.com');
  }

  render() {
    return (null);
  }
}

NotFound.defaultProps = {
};

const mapStateToProps = (state) => ({
  application: state.application,
});

NotFound.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  match: PropTypes.object,
};

export default connect(mapStateToProps)(NotFound);
