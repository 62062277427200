/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types';
import React from 'react';

class Svg extends React.Component {
  render() {
    const {
      icon, color = '#000000', collapse = false,
    } = this.props;
    switch (icon) {
      case 'download':
        return (
          <svg fill={color} height="24" viewBox="0 0 24 24" width="24" className="svg-download" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1111111,2 L11.1111111,15.8020833 L6.41666667,11.4010417 L5.13888889,12.5989583
            L11.3611111,18.4322917 L12,19.0052083 L12.6388889,18.4322917 L18.8611111,12.5989583 L17.5833333,11.4010417
            L12.8888889,15.8020833 L12.8888889,2 L11.1111111,2 Z M4,20.3333333 L4,22 L20,22 L20,20.3333333
            L4,20.3333333 Z" id="Shape" fill={color} fillRule="nonzero" mask="url(#mask-2)"/>
          </svg>
        );
      case 'dropdown':
        return (
          <svg fill={color} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <polygon id="Shape" points="7 9 12 15 17 9"/>
          </svg>
        );
      case 'arrow-down':
        return (
          <svg className={`arrow-down ${collapse ? 'collapsed' : ''}`} fill={color} enableBackground="new 0 0 50 50" height="15px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="15px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
            <rect fill="none" height="50" width="50"/>
            <polygon points="47.25,15 45.164,12.914 25,33.078 4.836,12.914 2.75,15 25,37.25 "/>
          </svg>
        );
      case 'arrow-up':
        return (
          <svg width="60px" height="80px" viewBox="0 0 50 80" xmlSpace="preserve" style={{transform: `rotate(90deg)`}}>
            <polyline fill="none" stroke="#FFFFFF" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
             points="45.63,75.8 0.375,38.087 45.63,0.375 "/>
          </svg>
        );
      case 'arrow-right':
        return (
          <svg className='arrow-right' fill='#FFFFFF' enableBackground="new 0 0 50 50" height="15px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="15px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
            <rect fill="none" height="50" width="50"/>
            <polygon points="47.25,15 45.164,12.914 25,33.078 4.836,12.914 2.75,15 25,37.25 "/>
          </svg>
        );
      case 'arrow-left':
        return (
          <svg className='arrow-left' fill='#FFFFFF' enableBackground="new 0 0 50 50" height="15px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="15px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
            <rect fill="none" height="50" width="50"/>
            <polygon points="47.25,15 45.164,12.914 25,33.078 4.836,12.914 2.75,15 25,37.25 "/>
          </svg>
        );
      case 'close':
        return (
          <svg version="1.1" id="Capa_1" className="cross-close" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="30px" height="30px" fill="white" viewBox="0 0 612 612" enableBackground="new 0 0 612 612" xmlSpace="preserve">
            <g id="cross">
              <g>
                <polygon points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397
                  306,341.411 576.521,611.397 612,575.997 341.459,306.011"/>
              </g>
            </g>
          </svg>
        );
      default:
        return null;
    }
  }
}

Svg.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  collapse: PropTypes.bool,
};

export default Svg;
