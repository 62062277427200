const constants = {
  URI: {},
  // old
  CARROSSERIE: {
    name: 'Défauts visuels (carrosserie / intérieur)',
    translationName: {
      de: 'Sehstörungen (Körper / Innenraum)',
      en: 'Visual defects (body / interior)',
      es: 'Defectos visuales (cuerpo / interior)',
      fr: 'Défauts visuels (carrosserie / intérieur)',
      it: 'Difetti visivi (corpo / interno)',
      pt: 'Defeitos visuais (corpo / interior)',
      nl: 'Visuele gebreken (carrosserie / interieur)',
      ref: 'Défauts visuels (carrosserie / intérieur)',
    },
    categoryTranslation: {
      de: 'KAROSSERIE UND INTERIEUR',
      en: 'BODY AND INTERIEUR',
      es: 'CARROCERÍA E INTERIOR',
      fr: 'CARROSSERIE ET HABITACLE',
      it: 'CARROZZERIA E INTERNI',
      pt: 'CARROÇARIA E INTERIOR',
      nl: 'CARROSSERIE EN INTERIEUR',
      ref: 'CARROSSERIE ET HABITACLE',
    },
  },
  MECANIQUE: {
    name: 'Défauts mécaniques',
    translationName: {
      de: 'Mechanische Fehler',
      en: 'Mechanical defects',
      es: 'Defectos mecánicas',
      fr: 'Défauts mécaniques',
      it: 'Difetti meccanici',
      pt: 'Defeitos mecânicas',
      nl: 'Mechanische fouten',
      ref: 'Défauts mécaniques',
    },
    categoryTranslation: {
      de: 'MECHANISCH',
      en: 'MECHANICAL',
      es: 'MECANICO',
      fr: 'MÉCANIQUE',
      it: 'MECCANICA',
      pt: 'MECÂNICA',
      nl: 'MECHANISCH',
      ref: 'MÉCANIQUE',
    },
  },
  AUTRES: {
    name: 'Autres défauts',
    translationName: {
      de: 'Andere Fehler',
      en: 'Others defects',
      es: 'Otros defectos',
      fr: 'Autres défauts',
      it: 'Altre difetti',
      pt: 'Outros defeitos',
      nl: 'Andere fouten',
      ref: 'Autres défauts',
    },
    categoryTranslation: {
      de: 'ANDERE',
      en: 'OTHERS',
      es: 'OTROS',
      fr: 'AUTRES',
      it: 'ALTRE',
      pt: 'OUTROS',
      nl: 'ANDERE',
      ref: 'AUTRES',
    },
  },
  TOTAL: {
    kind: 'TOTAL',
    name: 'Total général',
    nameTranslation: {
      de: 'Gesamtsumme',
      en: 'Total',
      es: 'Gran total',
      fr: 'Total général',
      it: 'Totale complessivo',
      pt: 'Total geral',
      nl: 'Eindtotaal',
      ref: 'Total général',
    },
  },
  TITLE: {
    kind: 'TITLE',
    name: 'Récapitulatif des dégats',
    nameTranslation: {
      de: 'Schadensübersicht',
      en: 'Damage summary',
      es: 'Resumen de daños',
      fr: 'Récapitulatif des dégats',
      it: 'Riepilogo del danno',
      pt: 'Resumo dos danos',
      nl: 'Samenvatting van de schade',
      ref: 'Récapitulatif des dégats',
    },
  },
  SIGNATURE: {
    kind: 'SIGNATURE',
    user: {
      name: "Signature de l'utilisateur",
      nameTranslation: {
        de: 'Benutzersignatur',
        en: 'User signature',
        es: 'Firma del usuario',
        fr: "Signature de l'utilisateur",
        it: "Firma dell'utente",
        pt: 'Assinatura do usuário',
        nl: 'Gebruikershandtekening',
        ref: "Signature de l'utilisateur",
      },
    },
    specialist: {
      name: 'Signature du spécialiste',
      nameTranslation: {
        de: 'Unterschrift des Spezialisten',
        en: "Specialist's signature",
        es: 'Firma del especialista',
        fr: `Signature du spécialiste`,
        it: 'Firma dello specialista',
        pt: 'Assinatura do especialista',
        nl: 'Handtekening van specialist',
        ref: `Signature du spécialiste`,
      },
    },
    client: {
      name: 'Signature du client',
      nameTranslation: {
        de: 'Client-Signatur',
        en: "Client's signature",
        es: 'Firma del cliente',
        fr: `Signature du client`,
        it: 'Firma del cliente',
        pt: 'Assinatura do cliente',
        nl: 'Handtekening van de klant',
        ref: `Signature du client`,
      },
    },
  },
  package: 'Forfait',

  // good
  lang: {
    de: {
      valueLabel: {
        yes: 'Ja',
        no: 'Nein',
      },
      total: 'ALLGEMEINES GESAMT',
      subTotal: 'ZWISCHENSUMME',
      identification: 'Identifizierung',
      estimate: 'Detaillierte Schätzung GT',
      pieces: 'Autoteile',
      labor: 'Arbeit',
      package: 'Paket',
      report: 'Inspektionsbericht',
      synthesis: 'ZUSAMMENFASSUNG',
      vehiclePhotos: 'FAHRZEUGFOTOS',
      insprectionReport: 'INSPEKTIONSBERICHT',
      downloadReport: 'Inspektionsbericht herunterladen',
      export: 'Fotos exportieren',
      equipments: 'Ausrüstung & Optionen',
      seeOptions: 'Alle Optionen anzeigen',
      seePhotos: 'Alle Fotos anzeigen',
      damages: 'Schaden',
      noDamages: 'An diesem Fahrzeug wurden keine Mängel gemeldet',
      totalHT: 'TOTAL HT',
      vat: 'BOTTICH',
      public: 'öffentlich',
      internal: 'intern',
      paint: 'Malerei',
      body: 'Karosserie',
      forfait: 'Flatrate',
      trim: 'Füllung',
      electricity: 'Strom',
      mechanics: 'Mechanisch',
      gt: 'GT Motive',
      laborPriceHour: 'Stundensatz',
      laborGrossCost: 'Bruttopreis',
      laborNetCost: 'Nettopreis',
      laborTime: 'Arbeitszeit',
      laborCost: 'Arbeitskosten',
      level: 'Schwierigkeit',
      depreciation: 'Abschreibung',
      element: 'Element',
      reparationType: 'Art des Schadens',
      severity: 'Schwere',
      repairOperationId: 'Reparaturart',
      category: 'Kategorie',
      checkpoint: 'Kontrollpunkt',
      response: 'Antworten',
      editPackageLabel: 'Die ausgegraute Spalte Bruttopreis kann geändert werden',
      editTMOLabel: 'Die abgeblendete Spalte Betriebszeit kann geändert werden',
      labelRequiredField: 'Dieses Feld wird benötigt',
      editSuccess: 'Update erfolgreich abgeschlossen',
      editError: 'Fehler beim Speichern',
    },
    en: {
      valueLabel: {
        yes: 'Yes',
        no: 'No',
      },
      total: 'TOTAL',
      subTotal: 'SUBTOTAL',
      identification: 'Identification',
      estimate: 'Detailed estimate GT',
      pieces: 'Pieces',
      labor: 'Labor',
      package: 'Package',
      report: 'Report',
      synthesis: 'SYNTHESIS',
      vehiclePhotos: 'VEHICLE PHOTOS',
      insprectionReport: 'INSPECTION REPORT',
      downloadReport: 'Download the inspection report',
      export: 'Export photos',
      equipments: 'Equipment & Options',
      seeOptions: 'See all options',
      seePhotos: 'See all photos',
      damages: 'Damages',
      noDamages: 'No defects have been reported on this vehicle',
      totalHT: 'TOTAL HT',
      vat: 'VAT',
      public: 'public',
      internal: 'internal',
      paint: 'Paint',
      body: 'Body',
      forfait: 'Package',
      trim: 'Trim',
      electricity: 'Electricity',
      mechanics: 'Mechanics',
      gt: 'GT Motive',
      laborPriceHour: 'Hourly rate',
      laborGrossCost: 'Gross Price',
      laborNetCost: 'Net Price',
      laborTime: 'Labor time',
      laborCost: 'Labor cost',
      level: 'Level',
      depreciation: 'Depreciation',
      element: 'Element',
      reparationType: 'Type of damage',
      severity: 'Severity',
      repairOperationId: 'Repair type',
      category: 'Category',
      checkpoint: 'Checkpoint',
      response: 'Response',
      editPackageLabel: 'The grayed-out Gross Price column can be modified',
      editTMOLabel: 'The grayed-out column Operation time can be modified',
      labelRequiredField: 'This field is required',
      editSuccess: 'Update successfully completed',
      editError: 'Error while saving',
    },
    es: {
      valueLabel: {
        yes: 'Si',
        no: 'No',
      },
      total: 'TOTAL GENERAL',
      subTotal: 'SUBTOTAL',
      identification: 'Identificación',
      estimate: 'Estimación detallada de GT',
      pieces: 'Autopartes',
      labor: 'Mano de obra',
      package: 'Paquete',
      report: 'Informe',
      synthesis: 'RESUMEN',
      vehiclePhotos: 'FOTOS DE VEHICULOS',
      insprectionReport: 'INFORME DE INSPECCIÓN',
      downloadReport: 'Descargue el informe de inspección',
      export: 'Exportar fotos',
      equipments: 'Equipamiento y Opciones',
      seeOptions: 'Ver todas las opciones',
      seePhotos: 'Ver todas las fotos',
      damages: 'Daño',
      noDamages: 'No se han reportado defectos en este vehículo',
      totalHT: 'HT TOTAL',
      vat: 'IVA',
      public: 'público',
      internal: 'interno',
      paint: 'Pintura',
      body: 'Carrocería',
      forfait: 'Tarifa plana',
      trim: 'Relleno',
      electricity: 'Electricidad',
      mechanics: 'Mecánica',
      gt: 'GT Motive',
      laborPriceHour: 'Tarifa por hora',
      laborGrossCost: 'Precio Bruto',
      laborNetCost: 'Precio Neto',
      laborTime: 'Tiempo de trabajo',
      laborCost: 'Costo de trabajo',
      level: 'Dificultad',
      depreciation: 'Depreciación',
      element: 'Elemento',
      reparationType: 'Tipo de daño',
      severity: 'Gravedad',
      repairOperationId: 'Tipo de reparación',
      category: 'Categoría',
      checkpoint: 'Control',
      response: 'Respuesta',
      editPackageLabel: 'La columna de precio bruto en gris se puede modificar',
      editTMOLabel: 'El tiempo de funcionamiento de la columna atenuada se puede modificar',
      labelRequiredField: 'Este campo es obligatorio',
      editSuccess: 'Actualización completada con éxito',
      editError: 'Error al guardar',
    },
    fr: {
      valueLabel: {
        yes: 'Oui',
        no: 'Non',
      },
      total: 'TOTAL GÉNÉRAL',
      subTotal: 'SOUS-TOTAL',
      identification: 'Identification',
      estimate: 'Estimation détaillée GT',
      pieces: 'Pièces',
      labor: "Main d'oeuvre",
      package: 'Forfait',
      report: 'Rapport',
      synthesis: 'SYNTHESE',
      vehiclePhotos: 'PHOTOS DU VEHICULE',
      insprectionReport: "RAPPORT D'INSPECTION COMPLET",
      downloadReport: "Télécharger le rapport d'inspection",
      export: 'Export photos',
      equipments: 'Equipements & Options',
      seeOptions: 'Voir toutes les options',
      seePhotos: 'Voir toutes les photos',
      damages: 'Dommages & dégâts',
      noDamages: 'Aucun défaut n’a été signalé sur ce véhicule',
      totalHT: 'TOTAL HT',
      vat: 'TVA',
      public: 'public',
      internal: 'interne',
      paint: 'Peinture',
      body: 'Carrosserie',
      forfait: 'Forfait',
      trim: 'Garnissage',
      electricity: 'Électricité',
      mechanics: 'Mécanique',
      gt: 'GT Motive',
      laborPriceHour: 'Tarif horaire',
      laborGrossCost: 'Prix Brut',
      laborNetCost: 'Prix Net',
      laborTime: 'Temps opération',
      laborCost: 'Coût opération',
      level: 'Difficulté',
      depreciation: 'Dépréciation',
      element: 'Element',
      reparationType: 'Type de dommage',
      severity: 'Sévérité',
      repairOperationId: 'Type de réparation',
      category: 'Catégorie',
      checkpoint: 'Point de contrôle',
      response: 'Réponse',
      editPackageLabel: 'La colonne grisée Prix Brut est modifiable',
      editTMOLabel: 'La colonne grisée Temps opération est modifiable',
      labelRequiredField: 'Ce champ est requis',
      editSuccess: 'Mise à jour effectuée avec succès',
      editError: 'Erreur lors de la sauvegarde',
    },
    it: {
      valueLabel: {
        yes: 'Si',
        no: 'No',
      },
      total: 'TOTALE GENERALE',
      subTotal: 'TOTALE PARZIALE',
      identification: 'Identificazione',
      estimate: 'Preventivo dettagliato GT',
      pieces: 'Ricambi auto',
      labor: 'Manodopera',
      package: 'Pacchetto',
      report: 'Rapporto',
      synthesis: 'SINTESI',
      vehiclePhotos: 'FOTO DEL VEICOLO',
      insprectionReport: "RAPPORTO SULL'ISPEZIONE",
      downloadReport: 'Scarica il rapporto di ispezione',
      export: 'Esporta foto',
      equipments: 'Attrezzature e Opzioni',
      seeOptions: 'Vedi tutte le opzioni',
      seePhotos: 'Vedi tutte le foto',
      damages: 'Danno',
      noDamages: 'Nessun difetto è stato segnalato su questo veicolo',
      totalHT: 'TOTALE HT',
      vat: 'IVA',
      public: 'pubblico',
      internal: 'interno',
      paint: 'Pittura',
      body: 'Carrozzeria',
      forfait: 'Tasso fisso',
      trim: 'Tagliare',
      electricity: 'Elettricità',
      mechanics: 'Meccanica',
      gt: 'GT Motive',
      laborPriceHour: 'Tariffa oraria',
      laborGrossCost: 'Prezzo lordo',
      laborNetCost: 'Prezzo netto',
      laborTime: 'Tempo di lavoro',
      laborCost: 'Costo del lavoro',
      level: 'Difficoltà',
      depreciation: 'Ammortamento',
      element: 'Element',
      reparationType: 'Tipo di danno',
      severity: 'Gravità',
      repairOperationId: 'Tipo di riparazione',
      category: 'Categoria',
      checkpoint: 'Checkpoint',
      response: 'Rispondere',
      editPackageLabel: 'La colonna grigia del prezzo lordo può essere modificata',
      editTMOLabel: 'La colonna di colore grigio Tempo di funzionamento può essere modificata',
      labelRequiredField: 'Questo campo è obbligatorio',
      editSuccess: 'Aggiornamento completato con successo',
      editError: 'Errore durante il salvataggio',
    },
    pt: {
      valueLabel: {
        yes: 'Sim',
        no: 'Não',
      },
      total: 'TOTAL GERAL',
      subTotal: 'SUBTOTAL',
      identification: 'Identificação',
      estimate: 'Estimativa detalhada GT',
      pieces: 'Autopeças',
      labor: 'Trabalho',
      package: 'Embalagem',
      report: 'Relatório',
      synthesis: 'RESUMO',
      vehiclePhotos: 'FOTOS DE VEÍCULOS',
      insprectionReport: 'RELATÓRIO DE INSPEÇÃO',
      downloadReport: 'Faça o download do relatório de inspeção',
      export: 'Exportar fotos',
      equipments: 'Equipamento e Opções',
      seeOptions: 'Ver todas as opções',
      seePhotos: 'Ver todas as fotos',
      damages: 'Danos',
      noDamages: 'Nenhum defeito foi relatado neste veículo',
      totalHT: 'HT TOTAL',
      vat: 'CUBA',
      public: 'público',
      internal: 'interno',
      paint: 'Pintura',
      body: 'Carroçaria',
      forfait: 'Taxa fixa',
      trim: 'Enchimento',
      electricity: 'Eletricidade',
      mechanics: 'Mecânica',
      gt: 'GT Motive',
      laborPriceHour: 'Taxa horária',
      laborGrossCost: 'Preço Bruto',
      laborNetCost: 'Preço Líquido',
      laborTime: 'Tempo de trabalho',
      laborCost: 'Custo do trabalho',
      level: 'Dificuldade',
      depreciation: 'Depreciação',
      element: 'Elemento',
      reparationType: 'Tipo de dano',
      severity: 'Gravidade',
      repairOperationId: 'Tipo de reparo',
      category: 'Categoria',
      checkpoint: 'Checkpoint',
      response: 'Responder',
      editPackageLabel: 'A coluna acinzentada de preço bruto pode ser modificada',
      editTMOLabel: 'A coluna acinzentada O tempo de operação pode ser modificado',
      labelRequiredField: 'Este campo é obrigatório',
      editSuccess: 'Atualização completada com sucesso',
      editError: 'Erro ao salvar',
    },
    nl: {
      valueLabel: {
        yes: 'Ja',
        no: 'Neen',
      },
      total: 'ALGEMEEN TOTAAL',
      subTotal: 'SUBTOTAAL',
      identification: 'Identificatie',
      estimate: 'Gedetailleerde schatting GT',
      pieces: 'Kamers',
      labor: 'Personeelsbestand',
      package: 'Vast bedrag',
      report: 'Verslag doen van',
      synthesis: 'SYNTHESE',
      vehiclePhotos: "FOTO'S VAN HET VOERTUIG",
      insprectionReport: 'VOLLEDIG INSPECTIERAPPORT',
      downloadReport: 'Download het inspectierapport',
      export: "Exporteer foto's",
      equipments: 'Uitrusting en opties',
      seeOptions: 'Bekijk alle mogelijkheden',
      seePhotos: "Zie alle foto's",
      damages: 'Schade en schade',
      noDamages: 'Aan dit voertuig zijn geen storingen gemeld',
      totalHT: 'TOTAAL HT',
      vat: 'VAT',
      public: 'openbaar',
      internal: 'intern',
      paint: 'Schilderen',
      body: 'Lichaam',
      forfait: 'Vast bedrag',
      trim: 'Vulling',
      electricity: 'Elektriciteit',
      mechanics: 'Mechanisch',
      gt: 'GT-motief',
      laborPriceHour: 'Uurtarief',
      laborGrossCost: 'Ruwe prijzen',
      laborNetCost: 'Nettoprijs',
      laborTime: 'Operatie tijd',
      laborCost: 'Kosten van de operatie',
      level: 'Moeilijkheidsgraad',
      depreciation: 'Afschrijving',
      element: 'Element',
      reparationType: 'Soort schade',
      severity: 'Ernst',
      repairOperationId: 'Reparatie type',
      category: 'Categorie',
      checkpoint: 'IJkpunt',
      response: 'Antwoord',
      editPackageLabel: 'De grijs weergegeven kolom met brutoprijzen kan worden gewijzigd',
      editTMOLabel: 'De grijs weergegeven kolom Bedrijfstijd kan worden gewijzigd',
      labelRequiredField: 'dit veld is verplicht',
      editSuccess: 'Update succesvol voltooid',
      editError: 'Fout bij opslaan',
    },
  },

  listLanguageToShow: [
    { value: '', label: 'Sélectionner une langue' },
    { value: 'de', label: 'Deutsch' },
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' },
    { value: 'nl', label: 'Nederlands' },
    { value: 'pt', label: 'Português' },
    { value: 'af', label: 'Afrikaans' },
    { value: 'sq', label: 'Shqip' },
    { value: 'ar', label: 'عربي' },
    { value: 'hy', label: 'Հայերէն' },
    { value: 'az', label: 'آذربایجان دیلی' },
    { value: 'eu', label: 'Euskara' },
    { value: 'be', label: 'Беларуская' },
    { value: 'bg', label: 'Български' },
    { value: 'ca', label: 'Català' },
    { value: 'zh-CN', label: '中文简体' },
    { value: 'zh-TW', label: '中文繁體' },
    { value: 'si', label: 'Cingalais' },
    { value: 'ko', label: '한국어' },
    { value: 'ht', label: 'Kreyòl ayisyen' },
    { value: 'hr', label: 'Hrvatski' },
    { value: 'da', label: 'Dansk' },
    { value: 'et', label: 'Eesti keel' },
    { value: 'fi', label: 'Suomi' },
    { value: 'fy', label: 'Frison' },
    { value: 'gl', label: 'Galego' },
    { value: 'cy', label: 'Cymraeg' },
    { value: 'ka', label: 'ქართული' },
    { value: 'el', label: 'Ελληνικά' },
    { value: 'iw', label: 'עברית' },
    { value: 'hi', label: 'हिन्दी' },
    { value: 'hu', label: 'Magyar' },
    { value: 'id', label: 'Bahasa Indonesia' },
    { value: 'ga', label: 'Gaeilge' },
    { value: 'is', label: 'Íslenska' },
    { value: 'ja', label: '日本語' },
    { value: 'jw', label: 'Javanais' },
    { value: 'lv', label: 'Latviešu' },
    { value: 'lt', label: 'Lietuvių kalba' },
    { value: 'mk', label: 'Македонски' },
    { value: 'ms', label: 'Malay' },
    { value: 'mt', label: 'Malti' },
    { value: 'no', label: 'Norsk' },
    { value: 'fa', label: 'فارسی' },
    { value: 'pl', label: 'Polski' },
    { value: 'ro', label: 'Română' },
    { value: 'ru', label: 'Русский' },
    { value: 'sr', label: 'Српски' },
    { value: 'sk', label: 'Slovenčina' },
    { value: 'sl', label: 'Slovensko' },
    { value: 'sv', label: 'Svenska' },
    { value: 'sw', label: 'Kiswahili' },
    { value: 'th', label: 'ไทย' },
    { value: 'tr', label: 'Türkçe' },
    { value: 'uk', label: 'Українська' },
    { value: 'ur', label: 'اردو' },
    { value: 'vi', label: 'Tiếng Việt' },
    { value: 'yi', label: 'ייִדיש' },
  ],
  formatDateTime: 'YYYY-MM-DDTHH:mm:ssZ',
  formatTime: 'HH:mm',
  typeAndLabelRepairCostTMO: [
    { label: 'Carrosserie T1', value: 'body_T1' },
    { label: 'Carrosserie T2', value: 'body_T2' },
    { label: 'Carrosserie T3', value: 'body_T3' },
    { label: 'Mecanique T1', value: 'mechanics_T1' },
    { label: 'Mecanique T2', value: 'mechanics_T2' },
    { label: 'Mecanique T3', value: 'mechanics_T3' },
    { label: 'Electricité T1', value: 'electricity_T1' },
    { label: 'Electricité T2', value: 'electricity_T2' },
    { label: 'Electricité T3', value: 'electricity_T3' },
    { label: 'Peinture T1', value: 'paint_T1' },
    { label: 'Peinture T2', value: 'paint_T2' },
    { label: 'Peinture T3', value: 'paint_T3' },
    { label: 'Garnissage T1', value: 'trim_T1' },
    { label: 'Garnissage T2', value: 'trim_T2' },
    { label: 'Garnissage T3', value: 'trim_T3' },
  ],
};

export default constants;
