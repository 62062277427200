/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import constants from '../../constants';
import BodyContext from '../../context/BodyContext';
import Thumbnail from '../Thumbnail';

const PhotoSynthesis = ({ section, openImage, navigatorLanguage, pdfView }) => {
  const { checkpoints } = section;
  const checkpointsFiltered = checkpoints.filter((checkpoint) => checkpoint.uri !== undefined);
  const firstCkPoints = checkpointsFiltered && checkpointsFiltered.slice(0, 4);
  const nextCkPoints = checkpointsFiltered && checkpointsFiltered.slice(4, checkpointsFiltered.length);
  return (
    <BodyContext.Consumer>
      {(setActiveLink) => (
        <div>
          <Row className='viewer-photos__first'>
            {firstCkPoints &&
              firstCkPoints.map((checkpoint, checkpointKey) => (
                <Col xs={12} md={3} key={checkpointKey} className='block__image viewer-photos__medium spacePadding'>
                  <Thumbnail
                    checkpoint={checkpoint}
                    checkpoints={checkpointsFiltered}
                    elementKey={checkpointKey}
                    openImage={openImage}
                    navigatorLanguage={navigatorLanguage}
                    noHide
                    enableHover={false}
                    classNames='anomalyDot__medium'
                    pdfView={pdfView}
                  />
                </Col>
              ))}
          </Row>
          <Row className='middle-xs'>
            <Col xs={12} md={9} className='spacePadding'>
              <Row>
                {nextCkPoints &&
                  nextCkPoints.map((checkpoint, checkpointKey) => (
                    <Col xs={12} md={1} key={firstCkPoints.length + checkpointKey} className='block__image viewer-photos__small spacePaddingXs'>
                      <Thumbnail
                        checkpoint={checkpoint}
                        checkpoints={checkpointsFiltered}
                        elementKey={firstCkPoints.length + checkpointKey}
                        openImage={openImage}
                        navigatorLanguage={navigatorLanguage}
                        noHide
                        enableHover={false}
                        classNames='anomalyDot__small'
                        pdfView={pdfView}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
            {checkpointsFiltered.length > 0 && (
              <Col xs={12} md={3} className='--hide-from-pdf spacePadding'>
                <a href='#' onClick={() => setActiveLink('photo')}>
                  <span className='blueTitle regular'>{_.get(constants, `lang.${navigatorLanguage}.seePhotos`)}</span>
                </a>
              </Col>
            )}
          </Row>
        </div>
      )}
    </BodyContext.Consumer>
  );
};

PhotoSynthesis.propTypes = {
  section: PropTypes.object,
  openImage: PropTypes.func,
  navigatorLanguage: PropTypes.string,
  pdfView: PropTypes.string,
};

export default PhotoSynthesis;
