import moment from 'moment';

const convertFloatToTime = (value) => {
  const momentTime = moment.duration(value, 'hours');
  const minutes = Math.round(momentTime.get('minutes') / 10) * 10;
  const minutesFormated = minutes < 10 ? `0${minutes}` : minutes;

  return {
    label: `${momentTime.get('hours')}h${minutesFormated}`,
    value: `${momentTime.get('hours')}:${minutesFormated}`,
  };
};

export default convertFloatToTime;
