import { createStore, combineReducers } from 'redux';

import application from '../reducers/application';

/* eslint-disable no-underscore-dangle */
const store = createStore(combineReducers({
  application,
}), process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__
  && window.__REDUX_DEVTOOLS_EXTENSION__() : {});
/* eslint-enable */

// const setUser = ({
//   user,
// }) => (
//   {
//     type: 'LOGIN_SUCCESS',
//     user,
//   }
// );

const configureStore = () => store;
// let user = localStorage.getItem('user');
// if (user) {
//   try {
//     user = JSON.parse(user);
//     store.dispatch(setUser({ user }));
//   } catch (e) {
//     // test
//   }
// }
//   return ;
// };

// store.subscribe(() => {
//   console.log('New state', store.getState());
// });

export default configureStore;
