export const extractKeysInArray = (array, keys) => {
  return array.map(obj => {
    let extracted = {};
    keys.forEach(key => {
      if (key in obj) extracted[key] = obj[key];
    });
    return extracted;
  });
};

export const extractKeysInObject = (obj, keys) => {
  let extracted = {};
  keys.forEach(key => {
    if (key in obj) extracted[key] = obj[key];
  });
  return extracted;
};
