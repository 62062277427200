/* eslint-disable no-mixed-operators */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import 'react-image-lightbox/style.css';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.min.css';
import Body from '../../components/Body';
import ImageViewer from '../../components/ImageViewer';
import RenderModalGtMotive from '../../components/RenderModalGtMotive';
import Elements from '../../components/Synthesis/Damages/Elements';
import Picture from '../../components/Synthesis/Damages/Picture';
import Text from '../../components/Text';
import constants from '../../constants';
import Helpers from '../../helpers';
import Api from '../../services/api';
import GenerateTable from '../../components/Synthesis/Damages/Table/GenerateTable';
import { extractKeysInObject } from '../../helpers/extractKeys';
import { areKeysPresent } from '../../helpers/areKeysPresent';
import PieceTable from '../../components/Synthesis/Damages/Table/PieceTable';
import TmoTable from '../../components/Synthesis/Damages/Table/TmoTable';
import ForfaitTable from '../../components/Synthesis/Damages/Table/ForfaitTable';
import { addOrUpdateObjects } from '../../helpers/addOrUpdateObjects';
import { keepOneObjectAndRemoveBooleans } from '../../helpers/keepOneObjectAndRemoveBooleans';
import { getRightReportPdf } from '../../helpers/getRightReportPdf';
import { getRightReportPdfV1 } from '../../helpers/getRightReportPdfV1';

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      repairCostSections: [],
      summary: {},
      listCards: [],
      images: [],
      currentImage: 0,
      widthPicture: 0,
      heightPicture: 0,
      checked: true,
      shownPrices: true,
      shownTotal: true,
      canEdit: false,
      canSeeFullReport: false,
      navigatorLanguage: '',
      languageCodesByMSA: [],
      dataToSend: [],
    };
    this.getInspection = this.getInspection.bind(this);
    this.checkIfPdf = this.checkIfPdf.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.openImage = this.openImage.bind(this);
    this.showCheckpoint = this.showCheckpoint.bind(this);
    this.onChangeFrevo = this.onChangeFrevo.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onCloseRequest = this.onCloseRequest.bind(this);
    this.onMovePrevRequest = this.onMovePrevRequest.bind(this);
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
    this.updateDateToSend = this.updateDateToSend.bind(this);
    this.saveUpdateFre = this.saveUpdateFre.bind(this);
  }

  getInspection(data, redirect) {
    const myPictures = [];
    /* eslint-disable no-unused-expressions */
    Api.getInspection(data).then((res) => {
      if (res.link && redirect) {
        window.location.replace(res.link);
        return;
      }
      const defectsSummary = _.get(res, 'summary.repairCost.defects');
      _.map(res.sections, async (section) => {
        _.map(section.checkpoints, (checkpoint) => {
          _.map(defectsSummary, (defect) => {
            if (checkpoint.refCheckpoint._id === defect.checkpoint?.refCheckpoint) {
              // eslint-disable-next-line no-param-reassign,prefer-destructuring
              checkpoint.refCheckpoint.photos = defect.photos;
            }
          });
          const kind = _.get(checkpoint, 'refCheckpoint.kind');
          if (kind === 'PhotoCheckpoint' && checkpoint.refCheckpoint.uri) {
            myPictures.push(checkpoint.refCheckpoint.uri);
          }
        });
      });

      let newRepairCostSections = [];
      let { shownPrices, shownTotal } = this.state;
      const { checked } = this.state;
      const { params = {} } = this.props.match;
      const lang = new URLSearchParams(this.props.location.search).get('lang') || navigator.language.slice(0, 2);

      const viewerConfig = Helpers.prepareConfig(res.viewerConfig);

      if (_.includes(viewerConfig, 'noPrices')) {
        shownPrices = false;
      }

      if (_.includes(viewerConfig, 'noTotal')) {
        shownTotal = false;
      }

      // user signature must come first
      if (res.signatures) {
        res.signatures &&
          res.signatures.user &&
          newRepairCostSections.push({
            sectionName: constants.SIGNATURE.user.name,
            sectionNameTranslation: _.get(constants, `SIGNATURE.user.nameTranslation.${lang}`),
            sectionKind: constants.SIGNATURE.kind,
            checkpoints: [res.signatures.user],
          });
      }
      const repairCostSections = Helpers.parseElements(res.sections, res.summary, res.translations, viewerConfig, lang);
      newRepairCostSections = [...newRepairCostSections, ...repairCostSections];

      // other signatures come by the end of the report
      if (res.signatures) {
        res.signatures &&
          res.signatures.specialist &&
          newRepairCostSections.push({
            sectionName: constants.SIGNATURE.specialist.name,
            sectionNameTranslation: _.get(constants, `SIGNATURE.specialist.nameTranslation.${lang}`),
            sectionKind: constants.SIGNATURE.kind,
            checkpoints: [res.signatures.specialist],
          });
        res.signatures &&
          res.signatures.client &&
          newRepairCostSections.push({
            sectionName: constants.SIGNATURE.client.name,
            sectionNameTranslation: _.get(constants, `SIGNATURE.client.nameTranslation.${lang}`),
            sectionKind: constants.SIGNATURE.kind,
            checkpoints: [res.signatures.client],
          });
      }
      const [translationOfTheFirstElement] = Object.values(res.translations);
      const documents = _.get(res, 'summary.documents');
      const canSeeFullReport = _.get(res, 'canSeeFullReport') || false;
      const pdfUrlToDownload = (documents && documents.length > 2 ) ? getRightReportPdf(documents, shownPrices, canSeeFullReport, lang) : getRightReportPdfV1(documents, shownPrices, lang);
      this.setState({
        AllRepairCostSections: newRepairCostSections,
        // clean total global null in reportCostSections
        repairCostSections: newRepairCostSections.filter(
          (section) =>
            !section.gTotal ||
            (!checked && (section.totalPrivate.min !== 0 || (section.totalPrivate.min !== 0 && section.totalPrivate.max !== 0))) ||
            (checked && (section.totalPublic.min !== 0 || (section.totalPublic.min !== 0 && section.totalPublic.max !== 0))),
        ),
        summary: _.get(res, 'summary'),
        pdfUrl: pdfUrlToDownload || _.get(res, 'summary.pdfSheetUrl'),
        pdfSheetUrl: _.get(res, 'summary.pdfSheetUrl'),
        zipFolder: _.get(res, 'summary.zipFolder'),
        images: myPictures,
        id: params.inspectionId,
        readableId: _.get(res, 'readableId'),
        canEdit: _.get(res, 'canEdit'),
        adminCanEdit: _.get(res, 'adminCanEdit'),
        canSeeFullReport: (new URLSearchParams(this.props.location.search).get('canSeeFullReport') === 'true') || canSeeFullReport,
        shownPrices,
        shownTotal,
        navigatorLanguage: lang,
        languageCodesByMSA: Object.keys(_.omit(translationOfTheFirstElement, ['_id', 'ref', 'updatedAt'])),
        generatedAt: _.get(res, 'generatedAt'),
      });
    });
  }

  async checkIfPdf() {
    let paramsToReturn = {};
    const { params = {} } = await this.props.match;
    if (params.inspectionId && params.inspectionId.length > 0) {
      const parseString = params.inspectionId.substr(params.inspectionId.length - 4);

      paramsToReturn =
        parseString === '.pdf'
          ? {
              redirect: true,
              inspectionId: params.inspectionId.substr(0, params.inspectionId.length - 4),
            }
          : params;
    }
    return paramsToReturn;
  }

  async componentDidMount() {
    const params = await this.checkIfPdf();
    this.getInspection({ inspectionId: params.inspectionId }, params.redirect);
  }

  openLightbox(url) {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      currentImage: _.indexOf(this.state.images, url),
    });
  }

  openImage(anomaly) {
    this.setState({
      currentAnomalySrc: anomaly.photo,
      lightboxAnomalyIsOpen: !this.state.lightboxAnomalyIsOpen,
    });
  }

  showCheckpoint({ checkpoint, checkpointKey, checkpoints, section, navigatorLanguage, canEdit, pdfView, adminCanEdit }) {
    if (checkpoint.value === null && !checkpoint.header) {
      return null;
    }
    const { valueLabel } = checkpoint;
    const color = Helpers.getColorFromValue(checkpoint);
    const imageC = section.photoScan === true;
    const urlImage = checkpoint.uriWithAno || checkpoint.uri;
    if (section.sectionKind === 'SIGNATURE') {
      return (
        <div key={checkpointKey} className='signature specialist'>
          <img src={section.checkpoints[0]} alt='signature' width={'100%'} />
        </div>
      );
    }
    if (section.sectionKind === 'REPAIR_COST') {
      // Todo: use labelTranslation on labels
      return (
        <div key={checkpointKey}>
          <div className='content frevo'>
            <div className='label'>
              <Text className='greyLabel weight500'>
                {navigatorLanguage && checkpoint.labelTranslation ? checkpoint.labelTranslation : checkpoint.label} {checkpoint.severityCm && `(${checkpoint.severityCm} cm)`}
              </Text>
            </div>
            <div className='value'>
              <Text className='blackValue'>{checkpoint.value}</Text>
              {checkpoint.repairOperationId && (
                <Text size={11} className='blackValue'>
                  Opération : {checkpoint.repairOperationId}
                </Text>
              )}
            </div>
            <div className='dot' />
            <div className='price'>
              <Text className='priceValue'>{checkpoint.finalCost === 'null' ? '0' : checkpoint.finalCost} €</Text>
            </div>
          </div>
        </div>
      );
    }
    // Todo: investigate what frevo and frevoPublic became
    if (section.sectionKind === 'REPAIR_COST_CATEGORY') {
      const { checked, shownPrices } = this.state;
      const isGtMotive = checkpoint.gtRepairCostInfo;
      const isForfaitWithDepreciation =
        (!checkpoint.labourTimeInfos || (checkpoint.labourTimeInfos && checkpoint.labourTimeInfos.length === 0)) &&
        !checkpoint.gtRepairCostInfo &&
        (checkpoint.privateCost || checkpoint.publicCost);

      const isTmo =
        checkpoint.labourTimeInfos &&
        checkpoint.labourTimeInfos.length > 0 &&
        checkpoint.labourTimeInfos.reduce((acc, labourTimeInfo) => acc + labourTimeInfo.computedLabourCost, 0) !== 0 &&
        !areKeysPresent(checkpoint.labourTimeInfos, ['pieceName'])
      const isPiece = checkpoint.labourTimeInfos &&
        checkpoint.labourTimeInfos.length > 0 &&
        checkpoint.labourTimeInfos.reduce((acc, labourTimeInfo) => acc + labourTimeInfo.computedLabourCost, 0) !== 0 &&
        areKeysPresent(checkpoint.labourTimeInfos, ['pieceName'])
      const commentCheckpoint = checkpoint.kind === 'CommentCheckpoint';
      const depreciationCheckpoint = checkpoint.privateCostDepreciation || checkpoint.publicCostDepreciation;
      const tableContainerCSS = depreciationCheckpoint ? 7 : isPiece ? 6 : 5;
      let commentContainerCSS, commentContainerCSSPdf;
      if (commentCheckpoint) { // S'il s'agit d'un commentaire alors on affiche les infos sur la totalité de l'espace
        commentContainerCSS = 12;
        commentContainerCSSPdf = 12;
      } else if (shownPrices && (checkpoint.privateCost || checkpoint.publicCost || isGtMotive || isTmo || isForfaitWithDepreciation)) {
        // Sinon si, il ne s'agit pas d'un commentaire et que l'on affiche les prix, l'espace (photos + Elements) du PDF prend 6/12 de l'espace
        commentContainerCSSPdf = isPiece ? 4 : 6;
        if (depreciationCheckpoint) {
          // Si nous avons une dépréciation alors l'espace (photos + Elements) prend 5/12 de l'espace
          commentContainerCSS = 5;
        } else {
          // Sinon si nous avons pas de dépréciation l'espace (photos + Elements) prend 7/12 de l'espace
          commentContainerCSS = isPiece ? 6 : 7;
        }
      } else {
        // Sinon si, il ne s'agit pas d'un commentaire et que les prix sont masqués, l'espace (photos + Elements) (PDF et Web) prend 11/12 de l'espace
        commentContainerCSSPdf = 7;
        commentContainerCSS = 7;
      }
      const subContentPdf = (shownPrices && (checkpoint.privateCost || checkpoint.publicCost || isGtMotive || isTmo || isForfaitWithDepreciation)) ? 9 : 11;
      const subContentPdfForPiece = isPiece ? 8 : 6;
      return (
        <div key={checkpointKey}>
          <div key={checkpointKey} className='damages'>
            {
              (checkpoints[checkpointKey - 1]?.category !== checkpoints[checkpointKey]?.category) &&
              <div className='repair-cost__panel__header__title textLeft' style={{ backgroundColor: 'rgb(197 196 196)', marginLeft: '0px', marginBottom: '15px', padding: '2px 5px' }}>
                <span>{checkpoints[checkpointKey].category}</span>
              </div>
            }
            <Row>
              <Col md={commentContainerCSS} xs={commentContainerCSSPdf} className="clearMargin">
                <Row>
                  <Col xs={12} style={{ display: 'flex' }}>
                    <Picture checkpoint={checkpoint} openImage={this.openImage} damagePicture={new URLSearchParams(this.props.location.search).get('damagePicture') || null}  />
                    <Col md={12} xs={subContentPdf} className='blocDetails' style={{ paddingLeft: '5px', paddingRight: '12px' }}>
                      <Elements checkpoint={checkpoint} navigatorLanguage={navigatorLanguage} classForPiece={isPiece && 'classForPiece'} />
                    </Col>
                  </Col>
                </Row>
              </Col>
              {shownPrices && (checkpoint.privateCost || checkpoint.publicCost || isGtMotive || isTmo || isForfaitWithDepreciation) ? (
                <Col md={tableContainerCSS} xs={subContentPdfForPiece} style={{ borderLeft: '0.1rem solid darkgray' }} className="clearMargin right">
                  <>
                    {isGtMotive && !pdfView ? <RenderModalGtMotive checkpoint={checkpoint} checkpointKey={checkpointKey} checked={checked} navigatorLanguage={navigatorLanguage} /> : null}
                    {
                      isTmo && (
                        <TmoTable
                          adminCanEdit={adminCanEdit}
                          checkpoint={checkpoint}
                          checkpointKey={checkpointKey}
                          checked={checked}
                          navigatorLanguage={navigatorLanguage}
                          inspectionId={this.state.id}
                          defectId={checkpoint.defectId}
                          reload={this.getInspection}
                          updateDateToSend={this.updateDateToSend}
                        />
                      )
                    }
                    {
                      isForfaitWithDepreciation && (
                        <ForfaitTable
                          adminCanEdit={adminCanEdit}
                          checkpoint={checkpoint}
                          checkpointKey={checkpointKey}
                          checked={checked}
                          navigatorLanguage={navigatorLanguage}
                          inspectionId={this.state.id}
                          defectId={checkpoint.defectId}
                          reload={this.getInspection}
                          updateDateToSend={this.updateDateToSend}
                        />
                      )
                    }
                    {
                      isPiece && (
                        <PieceTable
                          adminCanEdit={adminCanEdit}
                          checkpoint={checkpoint}
                          checkpointKey={checkpointKey}
                          checked={checked}
                          navigatorLanguage={navigatorLanguage}
                          inspectionId={this.state.id}
                          defectId={checkpoint.defectId}
                          reload={this.getInspection}
                          updateDateToSend={this.updateDateToSend}
                        />
                      )
                    }
                  </>
                </Col>
              ) : (
                !commentCheckpoint && (
                  <Col md={tableContainerCSS} xs={6} style={{ borderLeft: '0.1rem solid darkgray' }} className="clearMargin right tableCreate --hide-from-pdf">
                    {!adminCanEdit ? <div className='obliqueLine' /> : <GenerateTable
                      adminCanEdit={adminCanEdit}
                      canEdit={canEdit}
                      checkpoint={checkpoint}
                      checkpointKey={checkpointKey}
                      checked={checked}
                      navigatorLanguage={navigatorLanguage}
                      inspectionId={this.state.id}
                      defectId={checkpoint.defectId}
                      reload={this.getInspection}
                      updateDateToSend={this.updateDateToSend}
                    />}
                  </Col>
              ))}
            </Row>
            {((checkpoints[checkpointKey + 1] &&
              checkpoints[checkpointKey + 1]?.PartOfVehicleLabel !== checkpoints[checkpointKey]?.PartOfVehicleLabel) ||
              commentCheckpoint ||
              (!checkpoints[checkpointKey + 1]?.element && checkpoints[checkpointKey + 1]?.labelTranslation !== checkpoints[checkpointKey]?.labelTranslation)) && (
              <div className='divider newHeight' />
            )}
          </div>
        </div>
      );
    }
    if (_.get(checkpoint, 'kind') === 'CommentCheckpoint' && valueLabel === '') {
      return null;
    }
    switch (_.get(checkpoint, 'kind')) {
      case 'TITLE':
        return null;
      case 'PhotoCheckpoint':
        if (!checkpoint.uri) {
          return null;
        }
        return (
          checkpoint.uri && (
            <div className={`content picture ${imageC ? 'car' : 'doc'}`} key={checkpointKey}>
              {imageC && (
                <div className='pictureContainer'>
                  <ImageViewer element={checkpoint} elementKey={checkpointKey} openLightbox={this.openLightbox} openAnomaly={this.openImage} iconZoom={false} />
                </div>
              )}
              {!imageC && (
                <div className='blockDocument'>
                  {
                    pdfView ? (
                      <a className='blockImageDoc' href={checkpoint.uri} target="_blank" rel="noreferrer">
                        <img
                          src={`https://92g2xsphz7.execute-api.eu-west-3.amazonaws.com/prod/images/resize?url=${urlImage}&width=300`}
                          alt={urlImage}
                          className='image-doc'
                        />
                      </a>
                    ) : (
                      <div className='blockImageDoc' onClick={() => this.openLightbox(checkpoint.uri)}>
                        <img
                          src={`https://92g2xsphz7.execute-api.eu-west-3.amazonaws.com/prod/images/resize?url=${urlImage}&width=300`}
                          alt={urlImage}
                          className='image-doc'
                        />
                      </div>
                    )
                  }

                  <Text
                    className='greyLabel weight500'
                  >
                    {checkpoint.labelTranslation}
                  </Text>
                </div>
              )}
            </div>
          )
        );
      case 'EnumCheckpoint':
        return (
          <Row className='middle-xs mb6' key={checkpointKey}>
            {checkpoints[checkpointKey] && _.get(checkpoints[checkpointKey - 1], 'kind') === 'PhotoCheckpoint' && checkpoints[checkpointKey - 1].uri && <div className='divider' />}
            <Col xs={6} md={6} lg={7} className='label'>
              <Text className='greyLabel weight500 textRight'>
                {navigatorLanguage && checkpoint.labelTranslation ? checkpoint.labelTranslation : checkpoint.label} {checkpoint.severityCm && `(${checkpoint.severityCm} cm)`} :
              </Text>
            </Col>
            <Col xs={6} md={6} lg={5} className='value'>
              <Text className={`blackValue${color}`}>{valueLabel || ''}</Text>
              {checkpoint.repairOperationId && (
                <Text size={11} className='blackValue'>
                  Opération : {checkpoint.repairOperationId}
                </Text>
              )}
            </Col>
          </Row>
        );
      default:
        return (
          <Row className='middle-xs mb6' key={checkpointKey}>
            {checkpoints[checkpointKey] && _.get(checkpoints[checkpointKey - 1], 'kind') === 'PhotoCheckpoint' && checkpoints[checkpointKey - 1].uri && <div className='divider' />}
            <Col xs={6} md={6} lg={7} className='label'>
              <Text className='greyLabel weight500 textRight'>
                {navigatorLanguage && checkpoint.labelTranslation ? checkpoint.labelTranslation : checkpoint.label} {checkpoint.severityCm && `(${checkpoint.severityCm} cm)`} :
              </Text>
            </Col>
            <Col xs={6} md={6} lg={5} className='value'>
              <Text className={`blackValue${color}`}>{valueLabel || ''}</Text>
              {checkpoint.repairOperationId && (
                <Text size={11} className='blackValue'>
                  Opération : {checkpoint.repairOperationId}
                </Text>
              )}
            </Col>
          </Row>
        );
    }
  }

  onChangeFrevo() {
    const newChecked = !this.state.checked;
    const { AllRepairCostSections } = this.state;
    const newRepairCostSections = AllRepairCostSections.filter(
      (section) =>
        !section.gTotal ||
        (!newChecked && (section.totalPrivate.min !== 0 || (section.totalPrivate.min !== 0 && section.totalPrivate.max !== 0))) ||
        (newChecked && (section.totalPublic.min !== 0 || (section.totalPublic.min !== 0 && section.totalPublic.max !== 0))),
    );

    this.setState({ checked: newChecked, repairCostSections: newRepairCostSections });
  }

  onOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onCloseRequest() {
    this.setState({ lightboxIsOpen: false, lightboxAnomalyIsOpen: false });
  }

  onMovePrevRequest() {
    const { currentImage, images } = this.state;
    this.setState({ currentImage: (currentImage + (images.length - 1)) % images.length });
  }

  onMoveNextRequest() {
    const { currentImage, images } = this.state;
    this.setState({ currentImage: (currentImage + 1) % images.length });
  }

  selectItemToSendToServerByRepair(defect) {
    if (defect.th || defect.typeLabourCost || defect.levelLabourCost) {
      return extractKeysInObject(defect,
    [
        'id',
        'defectOrCheckpointId',
        'labourCost',
        'levelLabourCost',
        'publicCost',
        'privateCost',
        'typeLabourCost',
        'publicCostDepreciation',
        'privateCostDepreciation',
        'pieceName',
        'pieceCost',
        'isDelete'
      ])
    }
    return extractKeysInObject(defect,
      [
        'id',
        'defectOrCheckpointId',
        'publicCost',
        'privateCost',
        'publicCostDepreciation',
        'privateCostDepreciation',
        'isDelete'
      ]
    )
  }

  updateDateToSend(data) {
    const cleanDataAfterDelete = keepOneObjectAndRemoveBooleans(data);
    if (this.state.dataToSend.length === 0) {
      this.setState({ dataToSend : cleanDataAfterDelete });
    } else {
      const result = addOrUpdateObjects(this.state.dataToSend, cleanDataAfterDelete, 'id');
      this.setState({
        dataToSend: result,
      });
    }
  }
  async saveUpdateFre() {
    const clearFreToSend = this.state.dataToSend.map(this.selectItemToSendToServerByRepair);
    try {
      const res = await Api.addAndUpdateDefect(this.state.id, clearFreToSend);
      if (!res || res.error) {
        toast.error(res.error, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.getInspection({ inspectionId: this.state.id });
      }
      this.getInspection({ inspectionId: this.state.id });
      toast.success(_.get(constants, `lang.${this.state.navigatorLanguage}.editSuccess`), {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({
        dataToSend: [],
      });
    } catch (errInfo) {
      toast.error(errInfo, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  render() {
    const {
      type,
      repairCostSections,
      summary,
      pdfUrl,
      zipFolder,
      images,
      lightboxIsOpen,
      lightboxAnomalyIsOpen,
      id,
      readableId,
      checked,
      shownTotal,
      currentImage,
      currentAnomalySrc,
      navigatorLanguage,
      canEdit,
      adminCanEdit,
      canSeeFullReport,
      languageCodesByMSA,
      generatedAt,
      pdfSheetUrl,
      dataToSend,
    } = this.state;
    return (
      <>
        <Body
          location={this.props.location}
          history={this.props.history}
          repairCostSections={repairCostSections}
          pdfUrl={pdfUrl}
          pdfSheetUrl={pdfSheetUrl}
          zipFolder={zipFolder}
          checked={checked}
          shownTotal={shownTotal}
          showCheckpoint={this.showCheckpoint}
          type={type}
          openLightbox={this.openLightbox}
          openImage={this.openImage}
          id={id}
          readableId={readableId}
          canEdit={canEdit}
          adminCanEdit={adminCanEdit}
          canSeeFullReport={canSeeFullReport}
          navigatorLanguage={navigatorLanguage}
          summary={summary}
          images={images}
          lightboxIsOpen={lightboxIsOpen}
          currentImage={currentImage}
          onCloseRequest={this.onCloseRequest}
          onMovePrevRequest={this.onMovePrevRequest}
          onMoveNextRequest={this.onMoveNextRequest}
          lightboxAnomalyIsOpen={lightboxAnomalyIsOpen}
          currentAnomalySrc={currentAnomalySrc}
          onChangeFrevo={this.onChangeFrevo}
          isConcession={type === 'concession' || true}
          languageCodes={languageCodesByMSA}
          generatedAt={generatedAt}
          goToFullReportTab={new URLSearchParams(this.props.location.search).get('fullReport') || null}
          saveUpdateFre={this.saveUpdateFre}
          dataToSend={dataToSend}
        />
        <ToastContainer />
      </>
    );
  }
}

Homepage.defaultProps = {};

const mapStateToProps = (state) => ({
  application: state.application,
});

Homepage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  match: PropTypes.object,
};

export default connect(mapStateToProps)(Homepage);
