/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { HamburgerButton } from 'react-hamburger-button';
import Lightbox from 'react-image-lightbox';
import Drawer from 'react-motion-drawer';
import { connect } from 'react-redux';
import Select from 'react-select';
import constants from '../../constants';
import BodyContext from '../../context/BodyContext';
import findSectionWithKey from '../../helpers/findSectionWithKey';
import FullReport from '../FullReport';
import Photo from '../Photo';
import Svg from '../Svg';
import Synthesis from '../Synthesis';
import LogoId from './LogoId';
import { Button } from 'antd';

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
      active: 'synthesis',
      isOpen: false,
      select: {
        value: constants.listLanguageToShow.filter((option) => option.value === this.props.navigatorLanguage),
        options: constants.listLanguageToShow,
      },
      pdfUrlBySelectedLanguage: this.props.pdfUrl,
    };
    this.divTranslateRef = React.createRef();
    this.setActiveLink = this.setActiveLink.bind(this);
  }

  setValue(value) {
    this.setState((prevState) => ({
      select: {
        ...prevState.select,
        value: constants.listLanguageToShow.filter((option) => option.value === value),
      },
      pdfUrlBySelectedLanguage: this.props.pdfUrl,
    }));
  }

  handleChange(value) {
    this.setValue(value);
  }

  setActiveLink(link) {
    this.setState({
      active: link,
    });
  }

  componentDidMount() {
    this.setValue(this.props.navigatorLanguage);
    if (this.props.goToFullReportTab) {
      this.setActiveLink('fullReport');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.navigatorLanguage !== this.props.navigatorLanguage) {
      this.setValue(this.props.navigatorLanguage);
    }
  }

  onOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  changeLanguage(e) {
    let url = new URL(window.location.href);
    const lang = e.value;
    const checkIfLanguageIsSupported = this.props.languageCodes.includes(lang);
    if (!checkIfLanguageIsSupported) {
      let languageSelect = this.divTranslateRef.current.querySelector('select.goog-te-combo');
      languageSelect.value = lang;
      languageSelect.dispatchEvent(new Event('change'));
      const { location, history } = this.props;
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('lang')) {
        queryParams.delete('lang');
        history.replace({
          search: queryParams.toString(),
        });
      }
    } else {
      url.searchParams.set('lang', lang);
      window.location.href = url;
    }
    this.handleChange(lang);
  }

  render() {
    const { select, pdfUrlBySelectedLanguage } = this.state;

    const {
      readableId,
      repairCostSections,
      checked,
      type,
      canEdit,
      canSeeFullReport,
      navigatorLanguage,
      showCheckpoint,
      shownTotal,
      shownPrices,
      openLightbox,
      openImage,
      images,
      isConcession,
      onChangeFrevo,
      currentImage,
      lightboxIsOpen,
      onCloseRequest,
      onMovePrevRequest,
      onMoveNextRequest,
      lightboxAnomalyIsOpen,
      currentAnomalySrc,
      pdfSheetUrl,
      zipFolder,
      generatedAt,
      saveUpdateFre,
      dataToSend,
      adminCanEdit
    } = this.props;
    const { active } = this.state;
    const sections = repairCostSections;
    const sectionTitle = sections.length > 0 ? sections.find((section) => section.kind && section.kind === 'TITLE') : null;
    const sectionNoFre = sections.length > 0 ? sections.filter((section) => section.sectionKind !== 'REPAIR_COST' && section.sectionKind !== 'REPAIR_COST_CATEGORY') : null;
    const sectionIdentification = sectionNoFre && sectionNoFre.length > 0 ? findSectionWithKey(sectionNoFre, 'brand') : null;
    const sectionOpt = sectionNoFre && sectionNoFre.length > 0 ? findSectionWithKey(sectionNoFre, 'gps') : null;
    const sectionPhoto = sectionNoFre && sectionNoFre.length > 0 ? sectionNoFre.find((section) => section.photoScan === true) : null;
    const sectionFre = sections.length > 0 ? sections.filter((section) => section.sectionKind === 'REPAIR_COST_CATEGORY') : null;
    const sectionTotal = sections.length > 0 ? sections.find((section) => section.sectionKind === 'TOTAL') : null;
    const sectionsOther = sections.filter(
      (section) =>
        section &&
        section !== sectionTitle &&
        section !== sectionIdentification &&
        section !== sectionOpt &&
        section !== sectionPhoto &&
        section !== sectionTotal &&
        !sectionFre.includes(section),
    );

    const menuLinks = [
      { link: 'synthesis', name: _.get(constants, `lang.${navigatorLanguage}.synthesis`) },
      { link: 'photo', name: _.get(constants, `lang.${navigatorLanguage}.vehiclePhotos`) },
      { link: 'fullReport', name: _.get(constants, `lang.${navigatorLanguage}.insprectionReport`) },
    ];

    return (
      <div className='viewer'>
        <div className='viewer__header header'>
          <div className='header__nav py4 container'>
            <div className='--show-in-pdf filePdf'>
              <LogoId readableId={readableId} navigatorLanguage={navigatorLanguage} generatedAt={generatedAt} />
            </div>
            <Row className='middle-xs between-xs'>
              <Col xs={2} className='showIfMedium --hide-from-pdf'>
                <div className='hamburger'>
                  <HamburgerButton open={this.state.isOpen} onClick={() => this.onOpen()} width={18} height={15} strokeWidth={1} animationDuration={0.5} />
                </div>
              </Col>
              <Col xs={3} md={3} lg={2.5} className='hideIfMedium'>
                <LogoId readableId={readableId} navigatorLanguage={navigatorLanguage} generatedAt={generatedAt} />
              </Col>
              <Col xs={5} md={5} lg={5} className='header__nav__links nav hideIfMedium'>
                <ul className='row around-xs middle-xs nav__list m0 p0'>
                  {menuLinks.map((item, key) =>(
                    <li className={`${item.link === 'fullReport' ? 'col-xs-4' : 'col-xs-3'} nav__item`} key={key}>
                    <a href='#' className={`nav__link ${active === item.link && 'nav__link--active'}`} onClick={() => this.setActiveLink(item.link)}>
                      {item.name}
                    </a>
                  </li>
                  ))}
                </ul>
              </Col>
              <Col xs={4} md={2} lg={2} className='header__nav__translate --hide-from-pdf'>
                <div className='languages'>
                  <Select
                    value={select.value}
                    name='languageViewer'
                    options={select.options}
                    onChange={(e) => this.changeLanguage(e)}
                    className='basic-multi-select notranslate'
                    placeholder='Selectionner une langue'
                  />
                </div>
                <div ref={this.divTranslateRef} id='google_translate_element' style={{ display: 'none' }} />
                { dataToSend.length > 0 && (
                  <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <Button onClick={() => saveUpdateFre()}>Sauvegarder</Button>
                  </div>
                )}
              </Col>
              <Col xs={6} md={3} lg={2.5} className='header__nav__button button button--small --hide-from-pdf'>
                <Row className={`center-xs ${zipFolder ? 'mb4' : ''}`} justify="center">
                  <Col xs={10}>
                    {(pdfUrlBySelectedLanguage || pdfSheetUrl) && (
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={pdfUrlBySelectedLanguage ? pdfUrlBySelectedLanguage : pdfSheetUrl}
                        className='button__download button__download--yellow'>
                        <Svg icon='download' color='#FFFFFF' styles='margin-right: 10px;' />
                        <span className='hideIfLarge'>{_.get(constants, `lang.${navigatorLanguage}.downloadReport`)}</span>
                      </a>
                    )}
                  </Col>
                </Row>
                <Row className='center-xs' justify="center">
                  <Col xs={10}>
                    {zipFolder && (
                      <a target='_blank' rel='noopener noreferrer' href={zipFolder} className='button__download'>
                        <Svg icon='download' color='inherit' />
                        <span className='hideIfLarge'>{_.get(constants, `lang.${navigatorLanguage}.export`)}</span>
                      </a>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Drawer overlayClassName='overlayDrawer' open={this.state.isOpen} width={'70%'} className='drawer-normal'>
          <div className='drawer-burger'>
            <Row className='middle-xs' justify="end">
              <Col xs={1} md={1} className='hamburger'>
                <HamburgerButton open={this.state.isOpen} onClick={() => this.onOpen()} width={18} height={15} strokeWidth={1} animationDuration={0.5} />
              </Col>
              <Col xs={7} md={5}>
                <LogoId readableId={readableId} navigatorLanguage={navigatorLanguage} generatedAt={generatedAt} />
              </Col>
            </Row>
          </div>
          <div className='side-menu drawer'>
            {menuLinks.map((item, key) => (
              <div
                key={key}
                className={`${active === item.link && 'active'}`}
                onClick={() => {
                  this.setActiveLink(item.link);
                  this.onOpen();
                }}>
                <span>{item.name}</span>
                <Svg icon='arrow-right' />
              </div>
            ))}
          </div>
        </Drawer>
        <BodyContext.Provider value={this.setActiveLink}>
          {active === 'synthesis' && (
            <div className='viewer__synthesis synthesis container'>
              {lightboxIsOpen && <Lightbox mainSrc={images[currentImage]} onCloseRequest={onCloseRequest} enableZoom={false} />}
              {lightboxAnomalyIsOpen && <Lightbox mainSrc={currentAnomalySrc} onCloseRequest={onCloseRequest} enableZoom={false} />}
              <Synthesis
                sectionIdentification={sectionIdentification}
                sectionOpt={sectionOpt}
                sectionPhoto={sectionPhoto}
                sectionTitle={sectionTitle}
                sectionFre={sectionFre}
                sectionTotal={sectionTotal}
                checked={checked}
                navigatorLanguage={navigatorLanguage}
                canEdit={canEdit}
                adminCanEdit={adminCanEdit}
                canSeeFullReport={canSeeFullReport}
                shownTotal={shownTotal}
                shownPrices={shownPrices}
                showCheckpoint={showCheckpoint}
                onChangeFrevo={onChangeFrevo}
                isConcession={isConcession}
                type={type}
                openLightbox={openLightbox}
                openImage={openImage}
              />
            </div>
          )}
          {active === 'photo' && (
            <div className='viewer__photos container'>
              {lightboxIsOpen && <Lightbox mainSrc={images[currentImage]} onCloseRequest={onCloseRequest} enableZoom={false} />}
              {lightboxAnomalyIsOpen && <Lightbox mainSrc={currentAnomalySrc} onCloseRequest={onCloseRequest} enableZoom={false} />}
              {sectionPhoto && <Photo section={sectionPhoto} openImage={openImage} navigatorLanguage={navigatorLanguage} />}
            </div>
          )}
          {active === 'fullReport' && (
            <div id='viewer__full' className='viewer__full full container'>
              <FullReport
                sectionIdentification={sectionIdentification}
                sectionOpt={sectionOpt}
                sectionPhoto={sectionPhoto}
                sectionTitle={sectionTitle}
                sectionFre={sectionFre}
                sectionTotal={sectionTotal}
                sectionsOther={sectionsOther}
                showCheckpoint={showCheckpoint}
                shownTotal={shownTotal}
                checked={checked}
                navigatorLanguage={navigatorLanguage}
                canEdit={canEdit}
                canSeeFullReport={canSeeFullReport}
                openImage={openImage}
                openLightbox={openLightbox}
                onChangeFrevo={onChangeFrevo}
                isConcession={isConcession}
                images={images}
                currentImage={currentImage}
                lightboxIsOpen={lightboxIsOpen}
                lightboxAnomalyIsOpen={lightboxAnomalyIsOpen}
                onCloseRequest={onCloseRequest}
                onMovePrevRequest={onMovePrevRequest}
                onMoveNextRequest={onMoveNextRequest}
                currentAnomalySrc={currentAnomalySrc}
                pdfView={this.props.goToFullReportTab}
              />
            </div>
          )}
          <div id='modal-root' />
        </BodyContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  application: state.application,
});

Body.propTypes = {
  dispatch: PropTypes.func,
  updateHeight: PropTypes.func,
  pdfUrl: PropTypes.string,
  changeStep: PropTypes.func,
  canEdit: PropTypes.bool,
  adminCanEdit: PropTypes.bool,
  canSeeFullReport: PropTypes.bool,
  application: PropTypes.object,
  repairCostSections: PropTypes.array,
  summary: PropTypes.object,
  children: PropTypes.array,
  id: PropTypes.string,
  selected: PropTypes.number,
  isConcession: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.string,
  readableId: PropTypes.number,
  navigatorLanguage: PropTypes.string,
  showCheckpoint: PropTypes.func,
  openLightbox: PropTypes.func,
  openImage: PropTypes.func,
  images: PropTypes.array,
  onChangeFrevo: PropTypes.func,
  currentImage: PropTypes.number,
  lightboxIsOpen: PropTypes.bool,
  onCloseRequest: PropTypes.func,
  onMovePrevRequest: PropTypes.func,
  onMoveNextRequest: PropTypes.func,
  lightboxAnomalyIsOpen: PropTypes.bool,
  currentAnomalySrc: PropTypes.any,
  zipFolder: PropTypes.string,
  shownTotal: PropTypes.bool,
  dataToSend: PropTypes.array,
};

export default connect(mapStateToProps)(Body);
