import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col } from 'react-grid-system';
import constants from '../../constants';
import Svg from '../Svg';
import Total from '../Total';

const Damages = ({ section = {}, isCollapsed, type, showCheckpoint, checked, shownTotal, navigatorLanguage, canEdit, adminCanEdit, pdfView }) => {
  const [collapse, setCollapse] = useState(isCollapsed);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const { checkpoints } = section;

  const label = section.category && `${_.get(constants, `lang.${navigatorLanguage}.subTotal`)} ${section.categoryTranslation}`;
  let valueTotal;

  if (section.totalPrivate && !checked) valueTotal = section.totalPrivate;
  else if (checked && section.totalPublic) valueTotal = section.totalPublic;

  return (
    <div className='repair-cost__panel'>
      <div className='repair-cost__panel__header row'>
        <Col xs={12} md={12} className='repair-cost__panel__header__arrow'>
          <div className='repair-cost__panel__header__title textLeft' onClick={handleCollapse}>
            <Svg icon='arrow-down' color='white' collapse={!collapse} />
            <span>{section.categoryTranslation}</span>
          </div>
        </Col>
      </div>
      <div className={`repair-cost__panel__content collapse-content ${!collapse ? 'collapsed' : ''}`}>
        {checkpoints &&
          checkpoints.map((checkpoint, checkpointKey) =>
            showCheckpoint({
              checkpoint,
              checkpointKey,
              checkpoints,
              section,
              type,
              navigatorLanguage,
              canEdit,
              adminCanEdit,
              pdfView,
            }),
          )}
        {shownTotal && valueTotal && (valueTotal.min >= 0 || valueTotal.max >= 0) && <Total label={label} value={valueTotal} />}
      </div>
    </div>
  );
};

Damages.propTypes = {
  section: PropTypes.object,
  isCollapsed: PropTypes.bool,
  type: PropTypes.string,
  showCheckpoint: PropTypes.func,
  checked: PropTypes.bool,
  shownTotal: PropTypes.bool,
  navigatorLanguage: PropTypes.string,
  canEdit: PropTypes.bool,
  pdfView: PropTypes.string,
};

export default Damages;
