import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ImageViewer from '../ImageViewer';
import Svg from '../Svg';
import Modal from './Modal';

const LightBox = ({ imgProps, handleOpen, prevSrc, nextSrc }) => {
  const [anomalyLightBoxOpen, setAnomalyLightBoxOpen] = useState(false);

  const handlerPropagation = (anomalyLightBoxOpen) => {
    setAnomalyLightBoxOpen(anomalyLightBoxOpen);
  };

  // UseEffect to switch image based on left and right keyboard arrows
  useEffect(() => {
    if (anomalyLightBoxOpen) return;
    const handleKeyEvent = (event) => {
      if (event.keyCode === 37) {
        prevSrc();
      } else if (event.keyCode === 39) {
        nextSrc();
      } else if (event.keyCode === 'Escape' || event.keyCode === 27) {
        handleOpen('close');
      }
    };
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  }, [prevSrc, nextSrc, anomalyLightBoxOpen, handleOpen]);

  return (
    <Modal>
      <div className={`content picture car fixed`}>
        <div className='pictureContainer'>
          <div className='overlay' onClick={() => handleOpen('close')} />
          <div className='navButton navButton__prev' onClick={() => prevSrc()}>
            <Svg icon='arrow-left' color='white' />
          </div>
          <ImageViewer carouselView={true} handlerPropagation={handlerPropagation} {...imgProps} />
          <div className='navButton navButton__next' onClick={() => nextSrc()}>
            <Svg icon='arrow-right' color='white' />
          </div>
          <div className='header-icon'>
            <span className='close' onClick={() => handleOpen('close')}>
              <Svg icon='close' color='inherit' />
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

LightBox.propTypes = {
  imgProps: PropTypes.object,
  handleOpen: PropTypes.func,
  prevSrc: PropTypes.func,
  nextSrc: PropTypes.func,
};

export default LightBox;
