import { Form, Input, InputNumber, Popconfirm, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import constants from '../../../../constants';
import _ from 'lodash';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  inputType,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  const inputNode = inputType === 'number' ?
    <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
    : <Input ref={inputRef} onPressEnter={save} onBlur={save} />;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const ForfaitTable = ({
  adminCanEdit,
  checkpoint,
  checked,
  updateDateToSend,
}) => {
  const pb = checked ? 'publicCost' : 'privateCost';
  const depre = checked ? 'publicCostDepreciation' : 'privateCostDepreciation';
  const [dataToSend, setDataToSend] = useState([]);
  const [dataSource, setDataSource] = useState([
    {
      key: 0,
      defectId: checkpoint.defectId || checkpoint._id,
      name: _.get(constants, `lang.fr.package`),
      [pb]: 0,
      [depre]: 0,
      pn: 0,
      defectOrCheckpointId: checkpoint.defectId || checkpoint._id
    }
  ]);

  const defaultColumns = [
    {
      title: '',
      dataIndex: 'name',
      editable: false,
    },
    {
      title: 'Prix Brut',
      dataIndex: checked ? 'publicCost' : 'privateCost',
      editable: adminCanEdit,
      render: (cost) => <span className='textTable'>{cost && parseInt(cost) > 0 ? cost + '€' : '-'} </span>,
    },
    {
      title: 'Dépré ciation',
      dataIndex: depre,
      editable: adminCanEdit,
      width: '7%',
      render: (depreciation) => <span className='textTable'>{depreciation && parseInt(depreciation) > 0 ? depreciation + '%' : '-'} </span>,
    },
    {
      title: 'Prix Net',
      dataIndex: 'pn',
      editable: false,
      render: (cost) => <span className='textTable'>{cost && parseInt(cost) > 0 ? cost + '€' : '-'} </span>,
    },
    ...(adminCanEdit
      ? [{
        title: '',
        dataIndex: 'operation',
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="Confirmez-vous cette suppression?" onConfirm={() => handleDelete(record.key)}>
              <span><i className="fas fa-trash-alt"></i></span>
            </Popconfirm>
          ) : null,
        className: '--hide-from-pdf',
      }]
      : []),
  ];

  const handleSave = (row) => {
    let value = {};
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const oldRow = {...row};
    if (oldRow[depre] > 0) {
      value = { ...oldRow, pn: oldRow[pb] - ((oldRow[pb] * oldRow[depre]) / 100)}
    } else {
      value = { ...oldRow, pn: oldRow[pb] };
    }
    newData.splice(index, 1, {
      ...item,
      ...value,
    });
    setDataToSend(newData)
    setDataSource(newData);
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => (item.key !== key));
    const newDataToSend = dataSource.map(v => (v.key === key) && ({...v, isDelete: true}))
    setDataToSend(newDataToSend)
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['publicCost', 'privateCost', 'publicCostDepreciation', 'privateCostDepreciation'].includes(col.dataIndex) ? 'number' : 'text',
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });


  useEffect(() => {
    updateDateToSend(dataToSend);
  }, [dataToSend]);

  useEffect(() => {
    const data = [];
    const { privateCost, publicCost, privateCostDepreciation, publicCostDepreciation } = checkpoint;
    const netPrice = checked ? publicCost : privateCost;
    let depreciation = 0;
    let grossPrice = netPrice;
    if (privateCostDepreciation || publicCostDepreciation) {
      depreciation = checked ? publicCostDepreciation : privateCostDepreciation;
      grossPrice = netPrice / ((100 - depreciation) / 100);
    }
    data.push({
      key: 0,
      name: constants.package,
      id: checkpoint.defectId || checkpoint._id,
      [pb]: `${(grossPrice && grossPrice.toFixed(2)) || 0}`,
      [depre]: `${depreciation || 0}`,
      pn: `${(netPrice && netPrice.toFixed(2)) || 0}`,
      defectOrCheckpointId: checkpoint.defectId || checkpoint._id
    });
    setDataSource(data);
  }, []);

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};
export default ForfaitTable;
