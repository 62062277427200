import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import Lightbox from 'react-image-lightbox';
import Card from '../Card';
import Section from '../Section';
import Synthesis from '../Synthesis';

const FullReport = ({
  sectionIdentification,
  sectionOpt,
  sectionPhoto,
  sectionTitle,
  sectionFre,
  sectionTotal,
  sectionsOther,
  navigatorLanguage,
  canEdit,
  canSeeFullReport,
  checked,
  type,
  openImage,
  isConcession,
  onChangeFrevo,
  showCheckpoint,
  shownTotal,
  images,
  currentImage,
  lightboxIsOpen,
  onCloseRequest,
  lightboxAnomalyIsOpen,
  currentAnomalySrc,
  openLightbox,
  pdfView,
}) => {
  const itemEl = useRef(null);
  const [sectionNameParam, setSectionNameParam] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const item = itemEl.current;

      if (item) {
        const count = item.offsetTop;
        window.scrollBy({ top: count, left: 0, behavior: 'smooth' });
      }
    }, 1000);

     const sectionName= new URLSearchParams(window.location.search).get('sectionName') || null;
    setSectionNameParam(sectionName);
  }, []);

  const splicedSectionsOther = sectionsOther
    .filter((section) => {
      const { checkpoints } = section;
      const photoCkpointWithUri = checkpoints && checkpoints.filter((ckpoint) => ckpoint.kind === 'PhotoCheckpoint' && ckpoint.uri !== '' && ckpoint.uri !== undefined);
      const checkpointsWithValue =
        checkpoints && checkpoints.filter((ckpoint) => ckpoint.kind !== 'PhotoCheckpoint' && ckpoint.valueLabel !== '' && ckpoint.valueLabel !== undefined);
      const checkpointsValuable = checkpoints && checkpoints.filter((ckpoint) => photoCkpointWithUri.includes(ckpoint) || checkpointsWithValue.includes(ckpoint));

      return section.sectionKind === 'SIGNATURE' || (checkpointsValuable && checkpointsValuable.length > 0);
    })
    .reduce((newSections, item, index, array) => {
      if (index % 2 === 0) newSections.push(array.slice(index, index + 2));
      return newSections;
    }, []);

  return (
    <div>
      <div
        className='synthesis noMargin'
        id='full-report__synthesis'
      >
        {lightboxIsOpen && <Lightbox mainSrc={images[currentImage]} onCloseRequest={onCloseRequest} enableZoom={false} />}
        {lightboxAnomalyIsOpen && <Lightbox mainSrc={currentAnomalySrc} onCloseRequest={onCloseRequest} enableZoom={false} />}
        <Synthesis
          sectionIdentification={sectionIdentification}
          sectionOpt={sectionOpt}
          sectionPhoto={sectionPhoto}
          sectionTitle={sectionTitle}
          sectionFre={sectionFre}
          sectionTotal={sectionTotal}
          checked={checked}
          navigatorLanguage={navigatorLanguage}
          canEdit={canEdit}
          canSeeFullReport={canSeeFullReport}
          shownTotal={shownTotal}
          showCheckpoint={showCheckpoint}
          onChangeFrevo={onChangeFrevo}
          isConcession={isConcession}
          type={type}
          openLightbox={openLightbox}
          openImage={openImage}
          pdfView={pdfView}
        />
      </div>
      <div
        id='full-report__others-sections container'
        {...(!sectionNameParam && { ref: itemEl })}
      >
        {splicedSectionsOther &&
          splicedSectionsOther.map((group, sectionKey) => (
            <Row key={sectionKey}>
              {group.map((section, key) => {
                const { checkpoints, sectionName } = section;
                return (
                  <Col
                    xs={12}
                    md={6}
                    className='col-xs-12 col-md-6 full__block'
                    key={key}
                    id={`container-${key}`}
                    {...(sectionNameParam && { ref: sectionName === sectionNameParam ? itemEl : null })}
                  >
                    <div className='block'>
                      <Card
                        isConcession
                        section={section}
                        checkpoints={checkpoints}
                        titleClass='blueTitle'
                        checkpointId={1}
                        onChangeFrevo={onChangeFrevo}
                        checked={checked}
                        navigatorLanguage={navigatorLanguage}>
                        <div style={{ textAlign: 'left' }}>
                          <Section
                            section={section}
                            checkpoints={checkpoints}
                            type={type}
                            showCheckpoint={showCheckpoint}
                            navigatorLanguage={navigatorLanguage}
                            canEdit={canEdit}
                            canSeeFullReport={canSeeFullReport}
                            pdfView={pdfView}
                          />
                        </div>
                      </Card>
                    </div>
                  </Col>
                );
              })}
            </Row>
          ))}
      </div>
    </div>
  );
};

FullReport.propTypes = {
  sectionIdentification: PropTypes.object,
  sectionOpt: PropTypes.object,
  sectionPhoto: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionFre: PropTypes.array,
  sectionTotal: PropTypes.object,
  sectionsOther: PropTypes.array,
  navigatorLanguage: PropTypes.string,
  canEdit: PropTypes.bool,
  canSeeFullReport: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.string,
  openImage: PropTypes.func,
  isConcession: PropTypes.bool,
  onChangeFrevo: PropTypes.func,
  showCheckpoint: PropTypes.func,
  shownTotal: PropTypes.bool,
  images: PropTypes.array,
  currentImage: PropTypes.number,
  lightboxIsOpen: PropTypes.bool,
  onCloseRequest: PropTypes.func,
  onMovePrevRequest: PropTypes.func,
  onMoveNextRequest: PropTypes.func,
  lightboxAnomalyIsOpen: PropTypes.bool,
  currentAnomalySrc: PropTypes.string,
  openLightbox: PropTypes.func,
  pdfView: PropTypes.string,
};

export default FullReport;
