import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import _ from 'lodash';
import constants from '../constants';

import Text from './Text';

class Card extends React.Component {
  renderTitle({
    sectionName,
    sectionNameTranslation,
    isFrevo,
    checked,
    navigatorLanguage,
    sectionTitleHidden,
  }) {

    const label = sectionNameTranslation || sectionName;

    if (sectionTitleHidden) return '';
    if (!isFrevo) {
      return label;
    }
    let subLabel = '';
    if (checked && _.get(constants, `lang.${navigatorLanguage}.public`)) {
      subLabel = _.get(constants, `lang.${navigatorLanguage}.public`);
    } else if (!checked && _.get(constants, `lang.${navigatorLanguage}.internal`)) {
      subLabel = _.get(constants, `lang.${navigatorLanguage}.internal`);
    }
    return `${label} ${subLabel}`;
  }

  render() {
    const {
      className = '',
      titleClass = 'greyLabel weight200',
      checkpointId,
      checked,
      onChangeFrevo,
      isConcession,
      sectionName,
      section = {},
      navigatorLanguage,
    } = this.props;
    const {
      status,
      sectionKind,
    } = section;
    const isFrevo = sectionKind === 'REPAIR_COST' || (!section.noTrigger && section.header);

    if (isConcession && ((sectionKind === 'REPAIR_COST' && status === 'public' && checked) || (sectionKind === 'REPAIR_COST' && !status && !checked))) {
      return null;
    }
    return (
      <div className={`card ${className}${checkpointId ? ' item' : ''}`}>
        <div className="title">
          {section.header && <div />}
          <Text className={`${titleClass} margin-2`} size={section.header && 14}>
            {this.renderTitle({
              sectionName,
              ...section,
              checked,
              isFrevo,
              navigatorLanguage,
            })}
          </Text>
          {isConcession && isFrevo && <Switch
            onChange={onChangeFrevo}
            checked={checked}
            id="normal-switch"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#1f5288"
          />}
          {section.noTrigger && <div />}
        </div>
        {checkpointId && !section.header && <div className="divider"/>}
        {this.props.children}
      </div>
    );
  }
}

Card.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  titleClass: PropTypes.string,
  status: PropTypes.string,
  checked: PropTypes.bool,
  type: PropTypes.string,
  sectionName: PropTypes.string,
  section: PropTypes.object,
  checkpoints: PropTypes.array,
  checkpointId: PropTypes.number,
  onChangeFrevo: PropTypes.func,
  isConcession: PropTypes.bool,
  navigatorLanguage: PropTypes.string,
};

export default Card;
