import getColorFromValue from './getColorFromValue';
import parseElements from './parseElements';
import prepareConfig from './prepareConfig';

const helpers = {
  parseElements,
  prepareConfig,
  getColorFromValue,
};

export default helpers;

