import React from 'react';
import { Provider } from 'react-redux';
import Router from './router';
import configureStore from './stores/configureStore';
import { setConfiguration } from 'react-grid-system';
// ------------------------------------------------------
// Import scss
// ------------------------------------------------------
import './styles/styles.scss';

setConfiguration({ maxScreenClass: 'xl' });

function App() {
  return (
    <Provider store={configureStore()}>
    <Router/>
  </Provider>
  );
}

export default App;
