import React, { Children, useState } from 'react';
import TmoTable from './TmoTable';
import ForfaitTable from './ForfaitTable';
import PieceTable from './PieceTable';

const Menu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [head, ...tail] = Children.toArray(children);

  return (
    <div
      className='menu'
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {head}
      {isOpen && <div className='open'>{tail}</div>}
    </div>
  );
};

const Item = ({ children, onClick }) => {
  return (
    <div className='itemP' onClick={onClick}>
      {children}
    </div>
  );
};

const GenerateTable = ({
  canEdit,
  adminCanEdit,
  checkpoint,
  checkpointKey,
  checked,
  navigatorLanguage,
  inspectionId,
  defectId,
  reload,
  updateDateToSend,
}) => {
  const [tableToCreate, setTableToCreate] = useState(null);
  const tableMap = {
    forfait: <ForfaitTable
      adminCanEdit={adminCanEdit}
      canEdit={canEdit}
      checkpoint={checkpoint}
      checked={checked}
      navigatorLanguage={navigatorLanguage}
      inspectionId={inspectionId}
      defectId={defectId}
      reload={reload}
      updateDateToSend={updateDateToSend}
    />,
    tmo: <TmoTable
      adminCanEdit={adminCanEdit}
      canEdit={canEdit}
      checkpoint={checkpoint}
      checkpointKey={checkpointKey}
      checked={checked}
      navigatorLanguage={navigatorLanguage}
      inspectionId={inspectionId}
      defectId={defectId}
      reload={reload}
      updateDateToSend={updateDateToSend}
    />,
    piece: <PieceTable
      adminCanEdit={adminCanEdit}
      canEdit={canEdit}
      checkpoint={checkpoint}
      checkpointKey={checkpointKey}
      checked={checked}
      navigatorLanguage={navigatorLanguage}
      inspectionId={inspectionId}
      defectId={defectId}
      reload={reload}
      updateDateToSend={updateDateToSend}
    />
  }
  const getTableToRender = (table) => {
    return tableMap[table];
  }
  return (
    <>
      { !tableToCreate ? (<Menu>
        <Item>+</Item>
        <Item onClick={() => setTableToCreate('forfait')}>Forfait</Item>
        <Item onClick={() => setTableToCreate('tmo')}>TMO</Item>
        <Item onClick={() => setTableToCreate('piece')}>Pièces</Item>
      </Menu>) : getTableToRender(tableToCreate)
      }
    </>
  );
}

export default GenerateTable;
