import React from 'react';
import PropTypes from 'prop-types';

class Text extends React.Component {
  render() {
    const { className, size, children } = this.props;
    const style = {};

    if (size) {
      style.fontSize = size;
    }
    return (
      <div className={className} style={style}>{children}</div>
    );
  }
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  size: PropTypes.number,
};

export default Text;
