import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../constants';
import Section from '../Section';
import Text from '../Text';

const Identification = ({
  section, type, showCheckpoint, navigatorLanguage, canEdit, canSeeFullReport, pdfView,
}) => {
  const { checkpoints } = section;
  const newCheckpoints = checkpoints.filter((ckpoint) => ckpoint.kind !== 'DividerCheckpoint' && ckpoint.kind !== 'CommentCheckpoint');

  return (
    <div className="left__block block">
      <div className='card item'>
        <div className="title">
          <Text className='blueTitle'>{_.get(constants, `lang.${navigatorLanguage}.identification`)}</Text>
        </div>
      </div>
      <div className="divider"/>
      <Section
        section={section}
        type={type}
        checkpoints={newCheckpoints}
        showCheckpoint={showCheckpoint}
        navigatorLanguage={navigatorLanguage}
        canEdit={canEdit}
        canSeeFullReport={canSeeFullReport}
        pdfView={pdfView}
      />
    </div>
  );
};

Identification.propTypes = {
  section: PropTypes.object,
  type: PropTypes.string,
  showCheckpoint: PropTypes.func,
  navigatorLanguage: PropTypes.string,
  canEdit: PropTypes.bool,
  canSeeFullReport: PropTypes.bool,
  pdfView: PropTypes.string,
};

export default Identification;
