import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ViewCarousel = ({ photosCheckpoint, handlerPropagation, carouselView = false }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);


  const toggleLightbox = () => {
    setLightboxIsOpen(!lightboxIsOpen);
    if (carouselView) handlerPropagation(!lightboxIsOpen);
  };

  useEffect(() => {
    setPictures(
      photosCheckpoint.map((item) => ({
        item,
        source: item,
        url: item,
      })),
    );
  }, [photosCheckpoint]);

  const prevSrc = useCallback(() => (photosCheckpoint[(photoIndex + photosCheckpoint.length - 1) % photosCheckpoint.length]), [photoIndex, photosCheckpoint]);

  const nextSrc = useCallback(() => (photosCheckpoint[(photoIndex + 1) % photosCheckpoint.length]), [photoIndex, photosCheckpoint]);

  // UseEffect to switch image based on left and right keyboard arrows
  useEffect(() => {
    const handleKeyEvent = (event) => {
      if (event.keyCode === 37) {
        prevSrc();
      } else if (event.keyCode === 39) {
        nextSrc();
      }
    };
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  }, [prevSrc, nextSrc]);

  const render = () => {
    return (
      <div className={pictures.length > 1 ? 'anomalyCarousel carouselClearStatus' : 'anomalyCarousel carouselClearStatus no-thumbs'}>
        <Carousel>
          {pictures.map((photo) => (
            <div className='mt-5' key={photo} onClick={toggleLightbox}>
              <img className='media-img card-img-top card-img-hero' src={photo.source} alt='Alt text' style={{ width: '100%', cursor: 'zoom-in' }} />
            </div>
          ))}
        </Carousel>
        {lightboxIsOpen ? (
          <Lightbox
              wrapperClassName={photosCheckpoint.length <= 1 && 'anomalyLightbox'}
              mainSrc={photosCheckpoint[photoIndex]}
              nextSrc={nextSrc()}
              prevSrc={prevSrc()}
              onCloseRequest={toggleLightbox}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosCheckpoint.length - 1) % photosCheckpoint.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosCheckpoint.length)}
          />
        ) : null}
      </div>
    );
  };

  return render();
};

ViewCarousel.propTypes = {
  photosCheckpoint: PropTypes.array,
  handlerPropagation: PropTypes.func,
};

export default ViewCarousel;
